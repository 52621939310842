import { PaymentInputOption } from 'src/utils/constants/quotes';
import { GracePeriodValues } from 'src/utils/constants/distributors';

const makeInitialState = (values, parameter) => {
  return {
    distributorEquipment: values ? values.distributorEquipment : '',
    price: values ? values.price : '',
    equipmentInsuranceIncluded: values
      ? values.equipmentInsuranceIncluded
      : false,
    gracePeriod:
      values && values.initialPayment > 0
        ? values.gracePeriod
        : GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS,
    initialPayment: values ? values.initialPayment : '',
    residualPayment:
      Number(parameter.maxResidualValue) === 0 ? 1 : values.residualPayment,
    paymentInputOption: values
      ? values.paymentInputOption
      : PaymentInputOption.PERCENTAGE,
    isMsiModality: values ? values.isMsiModality : false
  };
};

export default makeInitialState;
