import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DetailRow from 'src/components/DetailRow';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ParameterCategoryDetailInfo = ({ distributor }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.LEASE')}
        value={
          distributor.quoteCategory ? (
            <Link to="/quoters/categories">
              {distributor.quoteCategory.name}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )
        }
      />
      {distributor.enableMsi && (
        <DetailRow
          name={t('DISTRIBUTORS_VIEW.PARAMETERS.MSI_DISCCOUNT')}
          value={
            distributor.discountMsi
              ? `${distributor.discountMsi.discountPercentage}%`
              : t('COMMON.NO_DATA')
          }
        />
      )}
    </>
  );
};

ParameterCategoryDetailInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default ParameterCategoryDetailInfo;
