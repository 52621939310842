import { GracePeriodValues } from 'src/utils/constants/distributors';
import {
  calculateEndDateFromGracePeriod,
  calculateFirstRentDateFromGracePeriod
} from 'src/utils/contracts';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default (values, parameter) => {
  if (values.enableMsi) {
    return {
      ...values,
      startDate: momentLocalTimezone(),
      firstRentDate: momentLocalTimezone(),
      gracePeriod: GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS,
      endDate: momentLocalTimezone().add(values.term - 1, 'months'),
      payday: Math.min(momentLocalTimezone().date(), 25)
    };
  }

  return {
    ...values,
    startDate: momentLocalTimezone(),
    firstRentDate:
      Number(values.initialPayment) === 0
        ? momentLocalTimezone()
        : calculateFirstRentDateFromGracePeriod(
            momentLocalTimezone(),
            values.gracePeriod || GracePeriodValues.ZERO
          ),
    gracePeriod:
      Number(values.initialPayment) === 0
        ? GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS
        : Number(parameter.defaultGracePeriod),
    endDate: calculateEndDateFromGracePeriod(
      momentLocalTimezone(),
      values.term,
      values.gracePeriod || GracePeriodValues.ZERO
    ),
    payday: Math.min(momentLocalTimezone().date(), 25)
  };
};
