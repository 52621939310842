import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import { DocumentType } from 'src/utils/constants/clients';
import DocumentsMenu from 'src/components/DocumentsMenu/DocumentsMenu';
import { Box } from '@mui/material';
import ContractStatus, {
  AutomaticDebitRequestSignStatus
} from 'src/utils/constants/contracts';
import PromissoryNotesStatus from 'src/utils/constants/promissoryNotes';
import {
  getAmendingAgreementSignStatus,
  getAutomaticDebitRequestSignStatus,
  getContractSignedStatus
} from 'src/utils/contract';
import getPromissoryNoteSignStatus from 'src/utils/promissoryNote';
import SendIcon from '@mui/icons-material/Send';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT,
  sendToSignAutomaticRequestDocumentAction
} from 'src/store/actions/contractActions';
import DocumentsContractMenu from 'src/views/contract/ContractDetailView/DocumentContractMenu/DocumentContractMenu';
import useStyles from '../../styles';

const DocumentDetail = ({ contract }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openConfirmationDialog, sentOpenConfirmationDialog] = useState(false);
  const isLoading = useSelector(
    isLoadingSelector([SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT.action])
  );
  const dispatch = useDispatch();

  const handleCloseAutomaticDebitRequestDialog = () => {
    sentOpenConfirmationDialog(false);
  };

  const sendToSignAutomaticDebitRequest = () => {
    dispatch(
      sendToSignAutomaticRequestDocumentAction(
        contract.id,
        handleCloseAutomaticDebitRequestDialog
      )
    );
  };

  const handleOpenActionsAutomaticDebitRequest = () => {
    switch (contract.automaticDebitRequest.signStatus) {
      case AutomaticDebitRequestSignStatus.DRAFT:
        return sentOpenConfirmationDialog(true);
      default:
        return null;
    }
  };

  return (
    <>
      <DetailRow
        name={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box component="div" className={classes.title}>
              {t('CONTRACT_VIEW.CONTRACT_PDF')}
            </Box>
            <Box sx={{ marginLeft: 1 }}>
              {getContractSignedStatus(contract)}
            </Box>
          </Box>
        }
        value={
          <DocumentLink
            link={
              contract.contractDoc ? contract.contractDoc.documentUrl : null
            }
            fileName={
              `Contrato ${contract.clientName}, folio ${contract.folio}` ?? null
            }
          />
        }
      >
        {contract.status !== ContractStatus.SIGNED && (
          <DocumentsContractMenu
            documentType={DocumentType.CONTRACT}
            contract={contract}
            contractId={contract.id}
            documents={Boolean(contract.contractDoc)}
          />
        )}
      </DetailRow>
      {contract?.promissoryNote?.document && (
        <DetailRow
          name={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box component="div" className={classes.title}>
                {t('CONTRACT_VIEW.PROMISSORY_NOTE')}
              </Box>
              <Box sx={{ marginLeft: 1 }}>
                {getPromissoryNoteSignStatus(contract.promissoryNote)}
              </Box>
            </Box>
          }
          value={
            <DocumentLink
              link={contract?.promissoryNote?.document?.documentUrl}
            />
          }
        >
          {contract?.promissoryNote?.status !==
            PromissoryNotesStatus.SIGNED && (
            <DocumentsContractMenu
              documentType={DocumentType.PROMISSORY_NOTE}
              promissoryNoteId={contract?.promissoryNote?.id}
              contractId={contract.id}
              contract={contract}
              documents={Boolean(contract?.promissoryNote?.document)}
            />
          )}
        </DetailRow>
      )}

      {contract.lastCapitalContribution && (
        <DetailRow
          name={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box component="div" className={classes.title}>
                {t('COMMON.AMENDING_AGREEMENT')}
              </Box>
              <Box sx={{ marginLeft: 1 }}>
                {getAmendingAgreementSignStatus(contract)}
              </Box>
            </Box>
          }
          value={
            <DocumentLink
              link={
                contract.lastCapitalContribution.document &&
                contract.lastCapitalContribution.document.documentUrl
              }
            />
          }
        >
          <DocumentsMenu
            contractId={contract.id}
            documentType={DocumentType.AMENDING_AGREEMENT}
            loadAmendingAgreements
            documents={contract.lastCapitalContribution.document}
            isDisabledAdd
          />
        </DetailRow>
      )}
      {contract.automaticDebitRequest && (
        <DetailRow
          name={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box component="div" className={classes.title}>
                {t('CONTRACT_VIEW.AUTOMATIC_DEBIT_REQUEST_DOC')}
              </Box>
              <Box sx={{ marginLeft: 1 }}>
                {getAutomaticDebitRequestSignStatus(contract)}
              </Box>
            </Box>
          }
          value={
            <DocumentLink
              link={contract.automaticDebitRequest.document.documentUrl}
            />
          }
        >
          {contract.automaticDebitRequest &&
            contract.automaticDebitRequest.signStatus ===
              AutomaticDebitRequestSignStatus.DRAFT && (
              <MoreMenu
                deleteDisabled
                editDisabled
                options={[
                  {
                    name: 'send-to-sign-automatic-debit-request',
                    label: 'COMMON.SEND_TO_SIGN',
                    disabled: !contract.automaticDebitRequest,
                    icon: <SendIcon />
                  }
                ]}
                handleClickOption={handleOpenActionsAutomaticDebitRequest}
              />
            )}
          <ConfirmationDialog
            open={openConfirmationDialog}
            handleClose={handleCloseAutomaticDebitRequestDialog}
            title={t(
              'CONTRACT_VIEW.SEND_TO_SIGN_AUTOMATIC_DEBIT_REQUEST_DOC_TITLE'
            )}
            description={t(
              'CONTRACT_VIEW.SEND_TO_SIGN_AUTOMATIC_DEBIT_REQUEST_DOC_DESCRIPTION'
            )}
            onSubmit={sendToSignAutomaticDebitRequest}
            isLoading={isLoading}
          />
        </DetailRow>
      )}
    </>
  );
};

DocumentDetail.propTypes = {
  contract: PropTypes.object.isRequired
};

export default DocumentDetail;
