import React from 'react';
import StatusIcon from 'src/components/StatusIcon';
import ContractStatus, {
  AmendingAgreementSignStatus,
  AutomaticDebitRequestSignStatus
} from './constants/contracts';
import { IconStatusAndColor } from './constants/common';
import { GracePeriodValues } from './constants/distributors';

export const getContractStatusIcon = contract => {
  switch (contract.status) {
    case ContractStatus.IN_PROGRESS:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );

    case ContractStatus.PENDING_SIGNATURE:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case ContractStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case ContractStatus.DRAFT:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.DRAFT"
          type={IconStatusAndColor.NONE.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

export const selectBackGroundColorFromContractStatus = contract => {
  switch (contract.status) {
    case ContractStatus.IN_PROGRESS:
      return 'rgba(40,167,69,0.1)';
    case ContractStatus.PENDING_SIGNATURE:
      return 'rgba(255,193,7,0.1)';
    case ContractStatus.SIGNED:
      return 'rgba(255,193,7,0.1)';
    case ContractStatus.DRAFT:
      return 'rgba(198,198,196,0.21)';
    default:
      return 'rgba(198,198,196,0.21)';
  }
};

export const getContractSignedStatus = contract => {
  if (!contract.contractDoc)
    return (
      <StatusIcon
        description="COMMON.NO_DATA"
        type={IconStatusAndColor.NONE.status}
      />
    );
  switch (contract.status) {
    case ContractStatus.IN_PROGRESS:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );

    case ContractStatus.PENDING_SIGNATURE:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case ContractStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case ContractStatus.DRAFT:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

export const getAmendingAgreementSignStatus = contract => {
  switch (contract.lastCapitalContribution.signStatus) {
    case AmendingAgreementSignStatus.PENDING_SIGNATURE:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case AmendingAgreementSignStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

export const getAutomaticDebitRequestSignStatus = contract => {
  switch (contract.automaticDebitRequest.signStatus) {
    case AutomaticDebitRequestSignStatus.PENDING_TO_SIGN:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.PENDING"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case AutomaticDebitRequestSignStatus.SIGNED:
      return (
        <StatusIcon
          description="ENUMS.DOCUMENT_STATUS.SIGNED"
          type={IconStatusAndColor.OK.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};

export const getGracePeriodFromMonthDiff = diff => {
  switch (diff) {
    case 0:
      return GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS;
    case 1:
      return GracePeriodValues.ZERO;
    case 2:
      return GracePeriodValues.THIRTY;
    case 3:
      return GracePeriodValues.SIXTY;
    case 4:
      return GracePeriodValues.NINETY;
    default:
      return GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS;
  }
};
