const ContractStatus = {
  DRAFT: 'DRAFT',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  IN_PROGRESS: 'IN_PROGRESS',
  ENDED: 'ENDED',
  CANCELLED: 'CANCELLED',
  SIGNED: 'SIGNED'
};

export const ContributionStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export const AmendingAgreementSignStatus = {
  SIGNED: 'SIGNED',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE'
};

export const AutomaticDebitRequestSignStatus = {
  DRAFT: 'DRAFT',
  PENDING_TO_SIGN: 'PENDING_TO_SIGN',
  SIGNED: 'SIGNED'
};

export const hasContractDigitalSign = contract => {
  return Boolean(contract.weetrustDocId);
};

export const hasPromissoryNoteDigitalSign = promissoryNote => {
  return Boolean(promissoryNote.weetrustDocId);
};


export const ContractDetailTabsIndex = {
  DETAIL: 'detail',
  AVALS: 'avals',
  CONTRIBUTIONS: 'contributions',
  RENT_BALANCE: 'rent-balance',
  PAYMENT_SCHEDULE: 'payment-schedule',
  ACCOUNTING_STATE: 'accounting-state'
};

export const ContractCreationOption = {
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  CREATE_CONTRACT_TO_DIGITAL_SIGN: 'CREATE_CONTRACT_TO_DIGITAL_SIGN'
};

export const ContractActionsOptions = {
  SEND_TO_DIGITAL_SIGN: 'SEND_TO_DIGITAL_SIGN',
  SIGN_MANUALLY: 'SIGN_MANUALLY',
  RESEND_TO_SIGN: 'RESEND_TO_SIGN',
  ACTIVATE_AUTOMATIC_BILLING: 'ACTIVATE_AUTOMATIC_BILLING',
  DESACTIVATE_AUTOMATIC_BILLING: 'DESACTIVATE_AUTOMATIC_BILLING',
  EDIT_CONTRACT_DATES: 'EDIT_CONTRACT_DATES',
  GENERATE_AUTOMATIC_REQUEST_DOCUMENT: 'GENERATE_AUTOMATIC_REQUEST_DOCUMENT',
  CANCEL_CONTRACT: 'CANCEL_CONTRACT'
};

export default ContractStatus;
