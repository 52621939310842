import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FormStepper from 'src/components/FormStepper/FormStepper';
import DialogTransition from 'src/components/DialogTransition';
import { createNewContractRequest } from 'src/store/actions/contractRequestActions';
import { getDistributorParameters } from 'src/store/actions/distributorActions';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const stepperLabels = ['COMMON.GENERAL', 'COMMON.CONFIRMATION'];

const ContractRequestForm = ({
  open,
  handleClose,
  quoteId,
  price,
  term,
  initialPayment,
  distributorId,
  iva,
  gracePeriod,
  initialPaymentDate,
  firstRentDate,
  clientData,
  client,
  isMsiModality
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState({
    id: quoteId,
    price,
    term,
    initialPayment,
    iva,
    gracePeriod,
    initialPaymentDate,
    firstRentDate,
    clientData,
    client,
    isMsiModality
  });
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const [labels, setLabels] = useState(stepperLabels);

  useEffect(() => {
    if (open) {
      dispatch(getDistributorParameters(distributorId));
    }
    return () => {
      setStep(0);
    };
  }, [open]);

  useEffect(() => {
    setLabels(stepperLabels);
  }, [data]);

  const isQuoteFromRentCalculator = term && initialPayment;

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = useCallback(() => {
    switch (step) {
      case 0:
        setData(initialState);
        handleClose();
        break;
      case 1:
        setStep(step - 1);
        break;
      default:
        setStep(step - 1);
        break;
    }
  }, [dispatch, step, setStep, handleClose]);

  const handleSubmit = useCallback(
    values => {
      dispatch(
        createNewContractRequest({ ...values, id: quoteId }, handleClose)
      );
      setData(initialState);
    },
    [data]
  );

  const handleCloseForm = () => {
    setData(initialState);
    handleClose();
  };

  return (
    <Box>
      <Dialog
        id="form-contract-request-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-contract-request-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
              setData({});
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('QUOTES_VIEW.CONTRACT_REQUEST')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent>
          <FormStepper index={step} labels={labels} />
          <CurrentStep
            activeStep={step}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
            onCloseForm={handleCloseForm}
            isQuoteFromRentCalculator={isQuoteFromRentCalculator}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

ContractRequestForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  quoteId: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  distributorId: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
  initialPayment: PropTypes.number.isRequired,
  iva: PropTypes.number.isRequired,
  gracePeriod: PropTypes.number.isRequired,
  initialPaymentDate: PropTypes.string.isRequired,
  firstRentDate: PropTypes.string.isRequired,
  clientData: PropTypes.object,
  client: PropTypes.object,
  isMsiModality: PropTypes.bool
};

export default ContractRequestForm;
