export const selectCatalog = state => state.catalog;
export const selectCatalogList = state => selectCatalog(state).items;
export const selectInsurersList = state => selectCatalog(state).insurers;
export const selectAgentsList = state => selectCatalog(state).agents;
export const selectExpenseTypesList = state =>
  selectCatalog(state).expenseTypes;
export const selectModelsList = state => selectCatalog(state).models.items;
export const selectModelName = state => selectCatalog(state).models.name;
export const selectKinshipsList = state => selectCatalog(state).kinships.items;
export const selectLineOfBusinessList = state =>
  selectCatalog(state).lineOfBusiness.items;
export const selectEconomicActivitiesList = state =>
  selectCatalog(state).economicActivities.items;
export const selectIndustriesList = state =>
  selectCatalog(state).industries.items;
export const selectMsiDiscountList = state =>
  selectCatalog(state).msiDiscount.items;
export const selectAdmistrativeRoles = state =>
  selectCatalog(state).administrativeRoles.items;
export const selectDenominationList = state =>
  selectCatalog(state).denominations.items;
export const selectMsiDiscountsList = state =>
  selectCatalog(state).msiDiscount.items;
export const selectDiscountGrid = state => selectCatalog(state).discountGrid;
