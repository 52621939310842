import { GracePeriodValues } from 'src/utils/constants/distributors';
import {
  calculateEndDateFromGracePeriod,
  calculateFirstRentDateFromGracePeriod
} from 'src/utils/contracts';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default values => {
  if (values.enableMsi) {
    return {
      ...values,
      startDate: momentLocalTimezone(),
      firstRentDate: momentLocalTimezone(),
      gracePeriod: GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS,
      endDate: momentLocalTimezone().add(values.term - 1, 'months'),
      payday: Math.min(momentLocalTimezone().date(), 25)
    };
  }

  return {
    ...values,
    startDate: momentLocalTimezone(),
    firstRentDate: calculateFirstRentDateFromGracePeriod(
      momentLocalTimezone(),
      values.gracePeriod || GracePeriodValues.ZERO
    ),
    gracePeriod: values.gracePeriod || GracePeriodValues.ZERO,
    endDate: calculateEndDateFromGracePeriod(
      momentLocalTimezone(),
      values.term,
      values.gracePeriod || GracePeriodValues.ZERO
    ),
    payday: Math.min(momentLocalTimezone().date(), 25)
  };
};
