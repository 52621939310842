import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  TextField,
  Tooltip,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  selectDistributorCatalogs,
  selectDistributorDetail
} from 'src/store/selectors/distributorSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_DISTRIBUTOR_AGENTS,
  LOAD_DISTRIBUTOR_CATALOGS,
  LOAD_DISTRIBUTOR_PARAMETER,
  getDistributorParameters,
  loadDistributorEquipmentsCatalog
} from 'src/store/actions/distributorActions';
import { getEquipmentDescription } from 'src/utils/equipments';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import DropdownMenu from 'src/components/DropdownMenu';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import { loadParameter } from 'src/store/actions/parameterActions';
import { Parameter } from 'src/utils/constants/parameters';
import { validateTermAndInitialPayment } from 'src/utils/calculator';
import { getDefaultDaysForFirstRentList } from 'src/utils/constants/distributors';
import { handleInitialPaymentChange } from 'src/utils/quotes';
import { formatAmount } from 'src/utils/formatAmout';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import { handleMsiCheckboxChange } from 'src/utils/distributorEquipment';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import EquipmentsAlert from './EquipmentsAlert';

const StepTwo = ({ data, handleNext, resetRef, handleBack }) => {
  const classes = useStyles();
  const parameter = useSelector(selectDistributorDetail);
  const maxInitialPayment = useSelector(selectIndividualParameter);
  let enableCheckbox = false;
  const initialState = useMemo(
    () =>
      makeInitialState(
        {
          ...data,
          equipmentInsuranceIncluded: data.distributor.isInsuranceIncluded
            ? 'true'
            : 'false',
          gracePeriod: Number(parameter.defaultGracePeriod),
          isMsiModality: false
        },
        parameter
      ),
    (enableCheckbox = parameter.enableMsi),
    [data, parameter]
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const equipments = useSelector(selectDistributorCatalogs);
  const maxResidualValue = Number(parameter.maxResidualValue);
  const isLoading = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );
  const loadingDistributorParameter = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_PARAMETER.action])
  );
  const isLoadingEquipments = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );

  const onNext = useCallback(
    async (values, setFieldError) => {
      const isValidated = validateTermAndInitialPayment(
        values,
        setFieldError,
        {
          minInitialPaymentPercentage: parameter.minInitialPayment,
          maxInitialPaymentPercentage: Number(maxInitialPayment)
        },
        t
      );
      if (!isValidated) return;
      handleNext({ ...values });
    },
    [handleNext, maxInitialPayment, parameter]
  );

  useEffect(() => {
    dispatch(loadDistributorEquipmentsCatalog(data.distributor.id, {}, false));
    dispatch(getDistributorParameters(data.distributor.id));
    dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validateOnChange
        validationSchema={validationSchema(parameter)}
        onSubmit={(values, { setFieldError }) => {
          onNext(values, setFieldError);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          resetForm,
          handleBlur,
          handleChange
        }) => {
          resetRef.current = () => resetForm();

          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <EquipmentsAlert
                  equipments={equipments}
                  loading={isLoading || isLoadingEquipments}
                  distributor={data.distributor}
                />
              </Box>
              <Box className={classes.container}>
                {enableCheckbox && (
                  <Tooltip
                    title={
                      !enableCheckbox
                        ? t('COMMON.MONTHS_WITHOUT_INTEREST_MODE_DISABLED')
                        : t('COMMON.MONTHS_WITHOUT_INTEREST_MODE')
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isMsiModality}
                          onChange={e => {
                            handleMsiCheckboxChange(
                              e,
                              setFieldValue,
                              values,
                              data,
                              dispatch,
                              loadDistributorEquipmentsCatalog
                            );
                          }}
                          name="isMsiModality"
                          color="primary"
                          disabled={!enableCheckbox}
                        />
                      }
                      label={t('COMMON.MONTHS_WITHOUT_INTEREST_MODE')}
                    />
                  </Tooltip>
                )}
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.distributorEquipment && errors.distributorEquipment
                  )}
                >
                  <Autocomplete
                    options={equipments}
                    clearIcon={null}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    disabled={isLoading || !equipments}
                    id="distributorEquipment"
                    getOptionLabel={option =>
                      option ? getEquipmentDescription(option) : ''
                    }
                    value={values.distributorEquipment ?? ''}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('distributorEquipment', value ?? '');
                      setFieldValue('price', value.price + value.iva ?? '');
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    helperText={
                      touched.distributorEquipment &&
                      t(errors.distributorEquipment)
                    }
                    label={
                      isLoading
                        ? `${t('COMMON.LOADING')} ${t('COMMON.EQUIPMENT')}`
                        : t('COMMON.EQUIPMENT')
                    }
                  />
                </FormControl>
                <Tooltip
                  disableFocusListener
                  title={t('COMMON.OBTAINED_FROM_DISTRIBUTOR_PARAMS')}
                >
                  <span>
                    <DropdownMenu
                      list={[
                        { key: 'true', name: t('COMMON.YES') },
                        { key: 'false', name: t('COMMON.NO') }
                      ]}
                      value={values.equipmentInsuranceIncluded}
                      setValue={value =>
                        setFieldValue(
                          'equipmentInsuranceIncluded',
                          value === 'true'
                        )
                      }
                      label={t(
                        'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                      )}
                      disabled
                      error={Boolean(
                        touched.equipmentInsuranceIncluded &&
                          errors.equipmentInsuranceIncluded
                      )}
                      errorText={
                        touched.equipmentInsuranceIncluded &&
                        t(errors.equipmentInsuranceIncluded)
                      }
                    />
                  </span>
                </Tooltip>

                <FormControl fullWidth>
                  <DropdownMenu
                    onBlur={handleBlur}
                    label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                    value={values.gracePeriod}
                    name="gracePeriod"
                    setValue={value => setFieldValue('gracePeriod', value)}
                    error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    errorText={touched.gracePeriod && t(errors.gracePeriod)}
                    list={getDefaultDaysForFirstRentList(
                      parameter.maxGracePeriod
                    )}
                    disabled={
                      isLoading ||
                      loadingDistributorParameter ||
                      values.isMsiModality ||
                      values.initialPayment === 0
                    }
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && t(errors.price)}
                  label={t('COMMON.PRICE')}
                  margin="normal"
                  name="price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={!data.distributor.isPriceEditable}
                  value={formatAmount(values.price)}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(
                    touched.residualPayment && errors.residualPayment
                  )}
                  fullWidth
                  helperText={
                    touched.residualPayment && t(errors.residualPayment)
                  }
                  label={t('CONTRACT_VIEW.RESIDUAL')}
                  margin="normal"
                  name="residualPayment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.residualPayment}
                  disabled={
                    values.checkboxValue ||
                    maxResidualValue === 0 ||
                    values.isMsiModality
                  }
                />

                <PaymentMultipleInput
                  firstInputName="paymentInputOption"
                  secondInputName="initialPayment"
                  disabled={!equipments || !values.distributorEquipment}
                  label={t('COMMON.INITIAL_PAYMENT')}
                  onChange={e => {
                    handleInitialPaymentChange(e, setFieldValue);
                  }}
                />
              </Box>

              <Box
                className={classes.tableBox}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={handleBack}
                  color="primary"
                  variant="outlined"
                  disabled={isLoading}
                >
                  {t('COMMON.BACK')}
                </Button>
                <Button
                  className={clsx(classes.buttons)}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {t('COMMON.CONTINUE')}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  resetRef: PropTypes.any,
  handleBack: PropTypes.func
};

export default StepTwo;
