import { Box, Card } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import loadQuoteCategories, {
  createQuoteCategoryAction,
  LOAD_QUOTE_CATEGORIES
} from 'src/store/actions/quoteActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import AddButton from 'src/components/AddButton/AddButton';
import QuoteCategoriesTable from './QuoteCategoriesTable';
import QuoteCategoryFormDialog from './QuoteCategoryFormDialog';

const QuoteCategoriesList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const categories = useSelector(selectCatalogList);
  const loading = useSelector(
    isLoadingSelector([LOAD_QUOTE_CATEGORIES.action])
  );
  const success = useSelector(successSelector([LOAD_QUOTE_CATEGORIES.action]));
  useEffect(() => {
    dispatch(loadQuoteCategories());
  }, []);
  const handleOpenQuoteForm = () => {
    setOpen(true);
  };

  const handleCloseQuoteForm = () => {
    setOpen(false);
  };

  const handleCreateQuote = useCallback(
    values => {
      dispatch(createQuoteCategoryAction(values));
      handleCloseQuoteForm();
    },
    [dispatch, handleCloseQuoteForm]
  );
  if (success && !loading && categories.length === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.QUOTE_CATEGORIES_TITLE" />
      </Card>
    );
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%'
        }}
      >
        <AddButton
          style={{ margin: '10px' }}
          title="COMMON.ADD"
          onClick={handleOpenQuoteForm}
        />
      </Box>
      <QuoteCategoriesTable categories={categories} loading={loading} />
      <QuoteCategoryFormDialog
        open={open}
        handleClose={handleCloseQuoteForm}
        onSubmit={handleCreateQuote}
      />
    </>
  );
};

export default QuoteCategoriesList;
