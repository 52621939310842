import IconStatusAndColor from 'src/utils/constants/common';
import StatusIcon from 'src/components/StatusIcon';
import { Tooltip } from '@mui/material';
import React from 'react';
import {
  CaseOptions
} from 'src/utils/constants/buroForm';

export const getNufiValidationList = (client, id) => {
  const validationFilter = [];
  validationFilter.push({
    clientId: id,
    name: `Client - ${client.fullName}`,
    clientValidation: client.nufiValidation || null,
    nufiScore: client.nufiScore ? client.nufiScore.clientGeneralScore : 0
  });
  if (client.avals.length) {
    client.avals.forEach(aval => {
      validationFilter.push({
        avalId: aval.id,
        name: `Aval - ${aval.fullName}`,
        clientValidation: aval.nufiValidation || null,
        nufiScore: client.nufiScore
          ? client.nufiScore.avalsGeneralScore.find(
              avalScore => avalScore.id === aval.id
            ).score
          : 0
      });
    });
  }
  return validationFilter;
};

const getMoffinCauses = causes => {
  const causesList = [];
  causes.forEach(cause => {
    if (cause) {
      causesList.push({ description: cause });
    }
  });
  return causesList;
};

export const getMoffinCreditProfileScore = (client, id) => {
  return {
    clientId: id,
    name: `Client - ${client.fullName}`,
    creditProfileScore: client.creditProfileValidation
      ? client.creditProfileValidation.moffinScore
      : 0,
    causes:
      client.creditProfileValidation &&
      getMoffinCauses([
        client.creditProfileValidation.moffinScoreCauseOne,
        client.creditProfileValidation.moffinScoreCauseTwo,
        client.creditProfileValidation.moffinScoreCauseThree,
        client.creditProfileValidation.moffinScoreCauseFour
      ]),
    rfc: client.rfc,
    lastUpdate: client.creditProfileValidation
      ? client.creditProfileValidation.lastUpdate
      : '',
    moffinAuthorizationMessageSent:
      client.moffinAuthorizationMessageSent || false,
    moffinScoreStatus: client.creditProfileValidation
      ? client.creditProfileValidation.moffinScoreStatus
      : '',
    documentReport: client.creditProfileValidation?.document || null
  };
};


export const getClientFinancialStatus = clientRatingValidation => {
  if (!clientRatingValidation.financingStatus)
    return null;
  switch (clientRatingValidation.financingStatus) {
    case CaseOptions.REVIEW:
      return (
        <Tooltip>
          <StatusIcon
            description="CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_STATUS.IN_REVIEW"
            type={IconStatusAndColor.WARNING.status}
          />
        </Tooltip>
      );

    case CaseOptions.DENIED:
      return (
        <StatusIcon
          description="CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_STATUS.DENIED"
          type={IconStatusAndColor.ERROR.status}
        />
      );
    case CaseOptions.WITH_AVAL:
      return (
        <StatusIcon
          description="CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_STATUS.APPROVED_WITH_AVAL"
          type={IconStatusAndColor.OK.status}
        />
      );
    case CaseOptions.WITHOUT_AVAL:
      return (
        <StatusIcon
          description="CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_STATUS.APPROVED_NO_AVAL"
          type={IconStatusAndColor.OK.status}
        />
      );
    case CaseOptions.NO_DATA:
        return (
          <StatusIcon
            description="CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_STATUS.NO_DATA"
            type={IconStatusAndColor.NONE.status}
          />
        );
    default:
      return null;
  }
};
