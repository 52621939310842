import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiscountGrid } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  CALCULATE_DISCOUNT_GRID,
  CLEAR_DISCOUNT_GRID
} from 'src/store/actions/catalogActions';
import CalculateGridForm from './CalculateGridForm/CalculateGridForm';
import DiscountGridTable from './DiscountGridTable';

const DiscountGridView = () => {
  const gridData = useSelector(selectDiscountGrid);
  const isLoading = useSelector(
    isLoadingSelector([CALCULATE_DISCOUNT_GRID.action])
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CLEAR_DISCOUNT_GRID });
  }, []);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <CalculateGridForm />
        <DiscountGridTable gridData={gridData} loading={isLoading} />
      </Box>
    </>
  );
};

export default DiscountGridView;
