// eslint-disable-next-line import/prefer-default-export
export const resendNipSeconds = 180;

const PercentageEnums = {
  BURO_FORM_MIN_RANGE_VALUE: 'BURO_FORM_MIN_RANGE_VALUE',
  BURO_FORM_MID_RANGE_VALUE: 'BURO_FORM_MID_RANGE_VALUE',
  BURO_FORM_MAX_RANGE_VALUE: 'BURO_FORM_MAX_RANGE_VALUE'
};

const PercentageLegalPersonEnums = {
  BURO_FORM_MIN_RANGE_VALUE_LEGAL_PERSON:
    'BURO_FORM_MIN_RANGE_VALUE_LEGAL_PERSON',
  BURO_FORM_MID_RANGE_VALUE_LEGAL_PERSON:
    'BURO_FORM_MID_RANGE_VALUE_LEGAL_PERSON',
  BURO_FORM_MAX_RANGE_VALUE_LEGAL_PERSON:
    'BURO_FORM_MAX_RANGE_VALUE_LEGAL_PERSON'
};

export const CaseOptions = {
  REVIEW: 'REVIEW',
  DENIED: 'DENIED',
  WITH_AVAL: 'WITH_AVAL',
  WITHOUT_AVAL: 'WITHOUT_AVAL',
  NO_DATA: 'NO_DATA',
};

export const CaseOptionsColors = {
  REVIEW: '#FFFFE0',
  DENIED: '#FFDAB9',
  WITH_AVAL: '#E0FFE0',
  WITHOUT_AVAL: '#98FB98'
};

export const BuroTabsIndex = {
  COMPANY: 'company',
  PERSON: 'physical-person'
};

export const generatePercentage = params => {
  const filteredParams = params.filter(param =>
    Object.values(PercentageEnums).includes(param.name)
  );

  const sortedParams = filteredParams.sort((a, b) => {
    const enumOrder = Object.values(PercentageEnums);
    return enumOrder.indexOf(a.name) - enumOrder.indexOf(b.name);
  });

  return sortedParams.map(param => param.value);
};

export const generateLegalPersonPercentage = params => {
  const filteredParams = params.filter(
    param =>
      Object.values(PercentageLegalPersonEnums).includes(param.name) &&
      param.name.includes('LEGAL_PERSON')
  );
  const sortedParams = filteredParams.sort((a, b) => {
    const enumOrder = Object.values(PercentageLegalPersonEnums);
    return enumOrder.indexOf(a.name) - enumOrder.indexOf(b.name);
  });

  return sortedParams.map(param => param.value);
};

export function organizeData(data) {
  const result = [
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    },
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    },
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    },
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    }
  ];

  data.forEach(param => {
    if (param.name.includes('FIRST') && !param.name.includes('LEGAL_PERSON')) {
      if (
        param.name.includes('RANGE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[0].range = param.value;
      }
      if (
        param.name.includes('CASE_ONE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[0].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[0].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[0].thirdCase = param.value;
      }
    }
    if (param.name.includes('SECOND') && !param.name.includes('LEGAL_PERSON')) {
      if (
        param.name.includes('RANGE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[1].range = param.value;
      }
      if (
        param.name.includes('CASE_ONE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[1].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[1].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[1].thirdCase = param.value;
      }
    }
    if (param.name.includes('THIRD') && !param.name.includes('LEGAL_PERSON')) {
      if (
        param.name.includes('RANGE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[2].range = param.value;
      }
      if (
        param.name.includes('CASE_ONE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[2].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[2].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[2].thirdCase = param.value;
      }
    }
    if (param.name.includes('FOURTH') && !param.name.includes('LEGAL_PERSON')) {
      if (
        param.name.includes('RANGE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[3].range = param.value;
      }
      if (
        param.name.includes('CASE_ONE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[3].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[3].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        !param.name.includes('LEGAL_PERSON')
      ) {
        result[3].thirdCase = param.value;
      }
    }
  });

  return result;
}

export function organizeLegalPersonData(data) {
  const result = [
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    },
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    },
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    },
    {
      range: null,
      firstCase: null,
      secondCase: null,
      thirdCase: null
    }
  ];

  data.forEach(param => {
    if (param.name.includes('FIRST') && param.name.includes('LEGAL_PERSON')) {
      if (param.name.includes('RANGE')) {
        result[0].range = Math.max(param.value, 100);
      }
      if (
        param.name.includes('CASE_ONE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[0].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[0].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[0].thirdCase = param.value;
      }
    }
    if (param.name.includes('SECOND') && param.name.includes('LEGAL_PERSON')) {
      if (param.name.includes('RANGE') && param.name.includes('LEGAL_PERSON')) {
        result[1].range = param.value;
      }
      if (
        param.name.includes('CASE_ONE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[1].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[1].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[1].thirdCase = param.value;
      }
    }
    if (param.name.includes('THIRD') && param.name.includes('LEGAL_PERSON')) {
      if (param.name.includes('RANGE') && param.name.includes('LEGAL_PERSON')) {
        result[2].range = param.value;
      }
      if (
        param.name.includes('CASE_ONE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[2].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[2].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[2].thirdCase = param.value;
      }
    }
    if (param.name.includes('FOURTH') && param.name.includes('LEGAL_PERSON')) {
      if (param.name.includes('RANGE') && param.name.includes('LEGAL_PERSON')) {
        result[3].range = Math.min(param.value, 400);
      }
      if (
        param.name.includes('CASE_ONE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[3].firstCase = param.value;
      }
      if (
        param.name.includes('CASE_TWO') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[3].secondCase = param.value;
      }
      if (
        param.name.includes('CASE_THREE') &&
        param.name.includes('LEGAL_PERSON')
      ) {
        result[3].thirdCase = param.value;
      }
    }
  });

  return result;
}

const removeNullFields = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
};

export function reorganizeData(data, percentages) {
  const result = {
    BURO_FORM_MIN_RANGE_VALUE: null,
    BURO_FORM_MID_RANGE_VALUE: null,
    BURO_FORM_MAX_RANGE_VALUE: null,
    FIRST_BURO_SCORE_RANGE: null,
    FIRST_BURO_SCORE_CASE_ONE: null,
    FIRST_BURO_SCORE_CASE_TWO: null,
    FIRST_BURO_SCORE_CASE_THREE: null,
    SECOND_BURO_SCORE_RANGE: null,
    SECOND_BURO_SCORE_CASE_ONE: null,
    SECOND_BURO_SCORE_CASE_TWO: null,
    SECOND_BURO_SCORE_CASE_THREE: null,
    THIRD_BURO_SCORE_RANGE: null,
    THIRD_BURO_SCORE_CASE_ONE: null,
    THIRD_BURO_SCORE_CASE_TWO: null,
    THIRD_BURO_SCORE_CASE_THREE: null,
    FOURTH_BURO_SCORE_RANGE: null,
    FOURTH_BURO_SCORE_CASE_ONE: null,
    FOURTH_BURO_SCORE_CASE_TWO: null,
    FOURTH_BURO_SCORE_CASE_THREE: null
  };
  data.forEach((param, index) => {
    if (index === 0) {
      result.FIRST_BURO_SCORE_RANGE = param.range;
      result.FIRST_BURO_SCORE_CASE_ONE = param.firstCase;
      result.FIRST_BURO_SCORE_CASE_TWO = param.secondCase;
      result.FIRST_BURO_SCORE_CASE_THREE = param.thirdCase;
    }
    if (index === 1) {
      result.SECOND_BURO_SCORE_RANGE = param.range;
      result.SECOND_BURO_SCORE_CASE_ONE = param.firstCase;
      result.SECOND_BURO_SCORE_CASE_TWO = param.secondCase;
      result.SECOND_BURO_SCORE_CASE_THREE = param.thirdCase;
    }
    if (index === 2) {
      result.THIRD_BURO_SCORE_RANGE = param.range;
      result.THIRD_BURO_SCORE_CASE_ONE = param.firstCase;
      result.THIRD_BURO_SCORE_CASE_TWO = param.secondCase;
      result.THIRD_BURO_SCORE_CASE_THREE = param.thirdCase;
    }
    if (index === 3) {
      result.FOURTH_BURO_SCORE_RANGE = param.range;
      result.FOURTH_BURO_SCORE_CASE_ONE = param.firstCase;
      result.FOURTH_BURO_SCORE_CASE_TWO = param.secondCase;
      result.FOURTH_BURO_SCORE_CASE_THREE = param.thirdCase;
    }
  });

  percentages.forEach((percentage, index) => {
    if (index === 0) {
      result.BURO_FORM_MIN_RANGE_VALUE = percentage;
    }
    if (index === 1) {
      result.BURO_FORM_MID_RANGE_VALUE = percentage;
    }
    if (index === 2) {
      result.BURO_FORM_MAX_RANGE_VALUE = percentage;
    }
  });

  return removeNullFields(result);
}

export function reorganizeLegalPersonData(data, percentages) {
  const result = {
    BURO_FORM_MIN_RANGE_VALUE_LEGAL_PERSON: null,
    BURO_FORM_MID_RANGE_VALUE_LEGAL_PERSON: null,
    BURO_FORM_MAX_RANGE_VALUE_LEGAL_PERSON: null,
    FIRST_BURO_SCORE_RANGE_LEGAL_PERSON: null,
    FIRST_BURO_SCORE_CASE_ONE_LEGAL_PERSON: null,
    FIRST_BURO_SCORE_CASE_TWO_LEGAL_PERSON: null,
    FIRST_BURO_SCORE_CASE_THREE_LEGAL_PERSON: null,
    SECOND_BURO_SCORE_RANGE_LEGAL_PERSON: null,
    SECOND_BURO_SCORE_CASE_ONE_LEGAL_PERSON: null,
    SECOND_BURO_SCORE_CASE_TWO_LEGAL_PERSON: null,
    SECOND_BURO_SCORE_CASE_THREE_LEGAL_PERSON: null,
    THIRD_BURO_SCORE_RANGE_LEGAL_PERSON: null,
    THIRD_BURO_SCORE_CASE_ONE_LEGAL_PERSON: null,
    THIRD_BURO_SCORE_CASE_TWO_LEGAL_PERSON: null,
    THIRD_BURO_SCORE_CASE_THREE_LEGAL_PERSON: null,
    FOURTH_BURO_SCORE_RANGE_LEGAL_PERSON: null,
    FOURTH_BURO_SCORE_CASE_ONE_LEGAL_PERSON: null,
    FOURTH_BURO_SCORE_CASE_TWO_LEGAL_PERSON: null,
    FOURTH_BURO_SCORE_CASE_THREE_LEGAL_PERSON: null
  };

  data.forEach((param, index) => {
    if (index === 0) {
      result.FIRST_BURO_SCORE_RANGE_LEGAL_PERSON = param.range;
      result.FIRST_BURO_SCORE_CASE_ONE_LEGAL_PERSON = param.firstCase;
      result.FIRST_BURO_SCORE_CASE_TWO_LEGAL_PERSON = param.secondCase;
      result.FIRST_BURO_SCORE_CASE_THREE_LEGAL_PERSON = param.thirdCase;
    }
    if (index === 1) {
      result.SECOND_BURO_SCORE_RANGE_LEGAL_PERSON = param.range;
      result.SECOND_BURO_SCORE_CASE_ONE_LEGAL_PERSON = param.firstCase;
      result.SECOND_BURO_SCORE_CASE_TWO_LEGAL_PERSON = param.secondCase;
      result.SECOND_BURO_SCORE_CASE_THREE_LEGAL_PERSON = param.thirdCase;
    }
    if (index === 2) {
      result.THIRD_BURO_SCORE_RANGE_LEGAL_PERSON = param.range;
      result.THIRD_BURO_SCORE_CASE_ONE_LEGAL_PERSON = param.firstCase;
      result.THIRD_BURO_SCORE_CASE_TWO_LEGAL_PERSON = param.secondCase;
      result.THIRD_BURO_SCORE_CASE_THREE_LEGAL_PERSON = param.thirdCase;
    }
    if (index === 3) {
      result.FOURTH_BURO_SCORE_RANGE_LEGAL_PERSON = param.range;
      result.FOURTH_BURO_SCORE_CASE_ONE_LEGAL_PERSON = param.firstCase;
      result.FOURTH_BURO_SCORE_CASE_TWO_LEGAL_PERSON = param.secondCase;
      result.FOURTH_BURO_SCORE_CASE_THREE_LEGAL_PERSON = param.thirdCase;
    }
  });

  percentages.forEach((percentage, index) => {
    if (index === 0) {
      result.BURO_FORM_MIN_RANGE_VALUE_LEGAL_PERSON = percentage;
    }
    if (index === 1) {
      result.BURO_FORM_MID_RANGE_VALUE_LEGAL_PERSON = percentage;
    }
    if (index === 2) {
      result.BURO_FORM_MAX_RANGE_VALUE_LEGAL_PERSON = percentage;
    }
  });

  return removeNullFields(result);
}
