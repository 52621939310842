import React, { useCallback, useMemo, useState } from 'react';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import ChangeStatus from '@mui/icons-material/ImportExport';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClientStatus, { PersonType } from 'src/utils/constants/clients';
import { addPointsPhone, capitalizeName } from 'src/utils/common';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import ReassignClientDialog from 'src/views/userViews/UserClientListView/ReasignClient/ReasignDialog';
import TooltipTypography from 'src/components/TooltipTypography/TooltipTypography';
import DisableClientIcon from '@mui/icons-material/PersonOff';
import RejectedIcon from '@mui/icons-material/PersonRemove';
import AddToProspect from '@mui/icons-material/PersonAdd';
import { getClientFinancialStatus } from 'src/utils/clientsHelper';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import { changeClientStatus } from 'src/store/actions/clientActions';
import { useDispatch } from 'react-redux';

const getStatusIcon = facturapiId => {
  if (facturapiId) {
    return (
      <StatusIcon
        description="ENUMS.CLIENT_VALIDATION_STATUS.VALIDATED"
        type={IconStatusAndColor.OK.status}
      />
    );
  }
  return (
    <StatusIcon
      description="ENUMS.CLIENT_VALIDATION_STATUS.NOT_VALIDATED"
      type={IconStatusAndColor.ERROR.status}
    />
  );
};

const ActiveClientMenuOptions = [
  {
    name: 'DISABLE',
    label: `CLIENTS_VIEW.DISABLE_CLIENT.TITLE`,
    icon: <DisableClientIcon />
  }
];

const prospectClientMenuOptions = [
  {
    name: 'DISABLE',
    label: `CLIENTS_VIEW.DISABLE_CLIENT.TITLE`,
    icon: <DisableClientIcon />
  },
  {
    name: 'ARCHIVE',
    label: `CLIENTS_VIEW.ARCHIVE_CLIENT.TITLE`,
    icon: <RejectedIcon />
  }
];

const leftClientMenuOptions = [
  {
    name: 'ARCHIVE',
    label: `CLIENTS_VIEW.ARCHIVE_CLIENT.TITLE`,
    icon: <RejectedIcon />
  }
];

const rejectedClientMenuOptions = [
  {
    name: 'PROSPECT',
    label: `CLIENTS_VIEW.PROSPECT_CLIENT.TITLE`,
    icon: <AddToProspect />
  }
];

const ClientsRow = ({
  id,
  name,
  paternalName,
  maternalName,
  phone,
  financingStatus,
  pendingDocs,
  personType,
  facturapiId,
  isAdminView,
  isDistributorDirector,
  kam,
  avalsWithPendingDocs,
  status
}) => {
  const [reasignOpen, setReasignOpen] = useState(false);
  const [openDisableClient, setOpenDisableClient] = useState(false);
  const [openArchiveClient, setOpenArchiveClient] = useState(false);
  const [openProspectClient, setOpenProspectClient] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fullName =
    personType === PersonType.PHYSICAL
      ? `${name} ${paternalName} ${maternalName}`
      : `${name}`;

  const handleMoreMenu = () => {
    setReasignOpen(true);
  };

  const menuOptions = {
    name: 'REASSIGN',
    label: `ENUMS.DISTRIBUTOR_CLIENT_ACTIONS.REASSIGN`,
    icon: <ChangeStatus />
  };

  const handleClickMore = option => {
    if (option === 'DISABLE') {
      setOpenDisableClient(true);
    }
    if (option === 'ARCHIVE') {
      setOpenArchiveClient(true);
    }
    if (option === 'PROSPECT') {
      setOpenProspectClient(true);
    }
  };
  const handleCloseDisableClient = () => {
    setOpenDisableClient(false);
  };

  const handleCloseArchiveClient = () => {
    setOpenArchiveClient(false);
  };

  const handleCloseProspectClient = () => {
    setOpenProspectClient(false);
  };

  const handleChangeClientStatus = useCallback(
    (clientStatus, handleCloseModal) => {
      dispatch(changeClientStatus(id, clientStatus, handleCloseModal));
    },
    [id]
  );

  const selecMenuOptions = useMemo(() => {
    switch (status) {
      case ClientStatus.ACTIVE:
        return ActiveClientMenuOptions;
      case ClientStatus.PROSPECT:
        return prospectClientMenuOptions;
      case ClientStatus.LEFT:
        return leftClientMenuOptions;
      case ClientStatus.REJECTED:
        return rejectedClientMenuOptions;
      default:
        return [];
    }
  }, [status]);

  const setAvalWhitPendingDocsMessage = pending => {
    switch (true) {
      case pending === 1:
        return t('TOOLTIP_MESSAGES.YOU_HAVE_ONE_AVAL_WITH_PEDING_DOCS');
      case pending > 1:
        return t('TOOLTIP_MESSAGES.YOU_HAVE_AVALS_WITH_PEDING_DOCS').replace(
          '{parameter}',
          pending
        );
      default:
        return t('TOOLTIP_MESSAGES.YOU_DONT_HAVE_AVALS_WITH_PEDING_DOCS');
    }
  };

  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>
        {fullName && fullName.length > 40 ? (
          <Tooltip title={fullName}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              <Link
                to={
                  isAdminView
                    ? `/clients/detail/${id}`
                    : `/user/clients/detail/${id}`
                }
              >
                {`${capitalizeName(fullName).slice(0, 40)}...`}
              </Link>
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '1.43'
            }}
          >
            <Link
              to={
                isAdminView
                  ? `/clients/detail/${id}`
                  : `/user/clients/detail/${id}`
              }
            >
              {capitalizeName(fullName)}
            </Link>
          </Typography>
        )}
      </TableCell>
      <TableCell align="center">{t(`CLIENTS_VIEW.${personType}`)}</TableCell>
      <TableCell align="center">{addPointsPhone(phone ?? '')}</TableCell>
      {isDistributorDirector && (
        <>
          <TableCell>
            <Link to={`/user/kam/${kam.id}`}>{kam.name}</Link>
          </TableCell>
          <TableCell align="center">
            <MoreMenu
              deleteDisabled
              editDisabled
              handleClickOption={handleMoreMenu}
              key={id}
              options={[menuOptions]}
            />
          </TableCell>
          <ReassignClientDialog
            open={reasignOpen}
            handleClose={() => setReasignOpen(false)}
            kam={kam}
            clientId={id}
          />
        </>
      )}
      {isAdminView && (
        <>
          <TableCell align="center">{pendingDocs}</TableCell>
          <TableCell align="center">
            <TooltipTypography
              title={setAvalWhitPendingDocsMessage(avalsWithPendingDocs)}
              text={avalsWithPendingDocs}
            />
          </TableCell>
          <TableCell align="center">{getStatusIcon(facturapiId)}</TableCell>
          {status === ClientStatus.PROSPECT && <TableCell align="center">{getClientFinancialStatus({financingStatus})}</TableCell>}

          <TableCell align="right">
            <MoreMenu
              options={selecMenuOptions}
              handleClickOption={handleClickMore}
              editDisabled
              deleteDisabled
            />
          </TableCell>
        </>
      )}
      <ConfirmationDialog
        open={openDisableClient}
        handleClose={() => setOpenDisableClient(false)}
        onSubmit={() =>
          handleChangeClientStatus(ClientStatus.LEFT, handleCloseDisableClient)
        }
        title={t('CLIENTS_VIEW.DISABLE_CLIENT.TITLE')}
        description={t('CLIENTS_VIEW.DISABLE_CLIENT.DESCRIPTION')}
      />

      <ConfirmationDialog
        open={openArchiveClient}
        handleClose={() => setOpenArchiveClient(false)}
        onSubmit={() =>
          handleChangeClientStatus(
            ClientStatus.REJECTED,
            handleCloseArchiveClient
          )
        }
        title={t('CLIENTS_VIEW.ARCHIVE_CLIENT.TITLE')}
        description={t('CLIENTS_VIEW.ARCHIVE_CLIENT.DESCRIPTION')}
      />

      <ConfirmationDialog
        open={openProspectClient}
        handleClose={handleCloseProspectClient}
        onSubmit={() =>
          handleChangeClientStatus(
            ClientStatus.PROSPECT,
            handleCloseProspectClient
          )
        }
        title={t('CLIENTS_VIEW.PROSPECT_CLIENT.TITLE')}
        description={t('CLIENTS_VIEW.PROSPECT_CLIENT.DESCRIPTION')}
      />
    </TableRow>
  );
};

ClientsRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  paternalName: PropTypes.string,
  maternalName: PropTypes.string,
  phone: PropTypes.string,
  financingStatus: PropTypes.string,
  pendingDocs: PropTypes.number,
  personType: PropTypes.string,
  facturapiId: PropTypes.string,
  isAdminView: PropTypes.bool,
  isDistributorDirector: PropTypes.bool,
  kam: PropTypes.object,
  avalsWithPendingDocs: PropTypes.number,
  status: PropTypes.string
};

export default ClientsRow;
