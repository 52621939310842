import getAllEquipments, {
  createNewEquipment,
  downloadeExportedEquipments,
  getClientEquipmentsById,
  getEquipmentById,
  updateEquipmentById
} from 'src/services/equipmentService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectEquipmentQueryOptions } from '../selectors/equipmentSelectors';
import uploadSingleFile from './commonActions';
import { showMessage } from './snackBarActions';

export const SAVE_OPTIONS = 'SAVE_OPTIONS';
export const CLEAR_OPTIONS = 'CLEAR_OPTIONS';
export const CLEAR_EQUIPMENTS = 'CLEAR_EQUIPMENT';
export const LOAD_EQUIPMENTS = asyncActionCreator('LOAD_EQUIPMENTS');
export const CREATE_EQUIPMENT = asyncActionCreator('CREATE_EQUIPMENT');
export const LOAD_EQUIPMENT_DETAIL = asyncActionCreator(
  'LOAD_EQUIPMENT_DETAIL'
);
export const UPDATE_EQUIPMENT = asyncActionCreator('UPDATE_EQUIPMENT');
export const LOAD_CLIENT_EQUIPMENTS = asyncActionCreator(
  'LOAD_CLIENT_EQUIPMENTS'
);

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectEquipmentQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.clientName) {
    reqOptions.clientName = queryOpts.clientName;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.assignation) {
    reqOptions.assignation = queryOpts.assignation;
  }
  if (queryOpts.mode) {
    reqOptions.mode = queryOpts.mode;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_OPTIONS });
};

export const setEquipmentOptions = options => dispatch => {
  dispatch({
    type: SAVE_OPTIONS,
    options
  });
};

export const loadEquipment = (options, sendLimit = true) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_EQUIPMENTS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, sendLimit);
    const data = await getAllEquipments(reqOptions);

    return dispatch({
      type: LOAD_EQUIPMENTS.success,
      ...data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_EQUIPMENTS.failure });
  }
};

export const loadEquipmentDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_EQUIPMENT_DETAIL.start });
    const data = await getEquipmentById(id);

    return dispatch({
      type: LOAD_EQUIPMENT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_EQUIPMENT_DETAIL.failure });
  }
};

export const downloadExcelEquipment = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadeExportedEquipments(options);
  } catch (error) {
    console.error(error);
  }
};

export const createEquipment = (
  values,
  closeCallback,
  setData
) => async dispatch => {
  try {
    dispatch({ type: CREATE_EQUIPMENT.start });

    let { invoicePdfUrl, invoiceXmlUrl } = values;

    if (invoicePdfUrl) {
      invoicePdfUrl = await uploadSingleFile(invoicePdfUrl);
    }
    if (invoiceXmlUrl) {
      invoiceXmlUrl = await uploadSingleFile(invoiceXmlUrl);
    }

    const equipmentValues = {
      ...values,
      invoicePdfUrl,
      invoiceXmlUrl,
      brandId: values.distributorEquipment.brand.id,
      model: values.distributorEquipment.model,
      year: values.distributorEquipment.year,
      distributorId: values.distributor.id,
      distributorEquipmentId: values.distributorEquipment.id
    };

    delete equipmentValues.distributorEquipment;
    delete equipmentValues.distributor;

    await createNewEquipment(equipmentValues);

    dispatch(
      showMessage({
        message: 'CREATE_EQUIPMENT',
        variant: 'success'
      })
    );
    closeCallback();
    setData();
    dispatch(loadEquipment());
    return dispatch({ type: CREATE_EQUIPMENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_EQUIPMENT.failure });
  }
};

export const updateEquipment = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_EQUIPMENT.start });

    if (values.equipmentType) {
      await updateEquipmentById({
        id,
        equipment: { ...values, equipmentTypeId: values.equipmentType.id }
      });
    } else {
      await updateEquipmentById({
        id,
        equipment: values
      });
    }

    dispatch(
      showMessage({
        message: 'UPDATE_EQUIPMENT',
        variant: 'success'
      })
    );
    dispatch(loadEquipmentDetail(id));
    return dispatch({ type: UPDATE_EQUIPMENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_EQUIPMENT.failure });
  }
};

export const updateEquipmentDocuments = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_EQUIPMENT.start });

    let { invoicePdfUrl, invoiceXmlUrl, price, iva } = values;

    if (price) {
      price = Number(price);
    }

    if (iva) {
      iva = Number(iva);
    }

    if (invoiceXmlUrl && invoiceXmlUrl.inDb) {
      invoiceXmlUrl = '';
    }

    if (invoicePdfUrl && invoicePdfUrl.inDb) {
      invoicePdfUrl = '';
    }

    if (invoicePdfUrl) {
      invoicePdfUrl = await uploadSingleFile(invoicePdfUrl);
    }
    if (invoiceXmlUrl) {
      invoiceXmlUrl = await uploadSingleFile(invoiceXmlUrl);
    }

    await updateEquipmentById({
      id,
      equipment: { iva, price, invoicePdfUrl, invoiceXmlUrl }
    });
    dispatch(
      showMessage({
        message: 'UPDATE_EQUIPMENT',
        variant: 'success'
      })
    );
    dispatch(loadEquipmentDetail(id));
    return dispatch({ type: UPDATE_EQUIPMENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_EQUIPMENT.failure });
  }
};

export const getClientEquipments = (
  id,
  setState = () => {}
) => async dispatch => {
  try {
    dispatch({ type: LOAD_CLIENT_EQUIPMENTS.start });
    const equipments = await getClientEquipmentsById(id);
    setState(equipments);
    return dispatch({ type: LOAD_CLIENT_EQUIPMENTS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CLIENT_EQUIPMENTS.failure });
  }
};
