import StatusIcon from 'src/components/StatusIcon';
import React from 'react';
import PromissoryNotesStatus from './constants/promissoryNotes';
import { IconStatusAndColor } from './constants/common';

const getPromissoryNoteSignStatus = promissoryNote => {
    if (
      !promissoryNote.document
    ) {
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
    }
    switch (promissoryNote.status) {
      case PromissoryNotesStatus.IN_PROGRESS:
        return (
          <StatusIcon
            description="ENUMS.DOCUMENT_STATUS.SIGNED"
            type={IconStatusAndColor.OK.status}
          />
        );
      case PromissoryNotesStatus.PENDING_SIGNATURE:
        return (
          <StatusIcon
            description="ENUMS.DOCUMENT_STATUS.PENDING"
            type={IconStatusAndColor.WARNING.status}
          />
        );
      case PromissoryNotesStatus.SIGNED:
        return (
          <StatusIcon
            description="ENUMS.DOCUMENT_STATUS.SIGNED"
            type={IconStatusAndColor.OK.status}
          />
        );
      case PromissoryNotesStatus.DRAFT:
        return (
          <StatusIcon
            description="COMMON.NO_DATA"
            type={IconStatusAndColor.NONE.status}
          />
        );
      default:
        return (
          <StatusIcon
            description="COMMON.NO_DATA"
            type={IconStatusAndColor.NONE.status}
          />
        );
    }
  };

  export default getPromissoryNoteSignStatus;