import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { history } from 'src/store';
import { ConnectedRouter } from 'connected-react-router';
import LoginView from 'src/views/auth/LoginView/LoginView';
import DashboardViews from 'src/views';
import UserType from 'src/utils/constants/auth';
import AssignPasswordView from 'src/views/auth/AssignPasswordView/AssignPasswordView';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import UserVerificationView from 'src/views/auth/UserVerificationView/UserVerificationView';
import RecoverPasswordView from 'src/views/auth/RecoverPasswordView/RecoverPasswordView';
import RecoverPasswordConfirmationView from 'src/views/auth/RecoverPasswordConfirmationView/RecoverPasswordConfirmationView';
import BureauFormView from 'src/views/publicViews/BureauFormView';

const AppRoutes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/admin/login">
        <LoginView userType={UserType.ADMIN} />
      </Route>
      {/* Commented until we update kams backoffice */}
      {/* <Route path="/user/login">
        <LoginView userType={UserType.DISTRIBUTOR} />
      </Route> */}
      <Route path="/buro-validation-form/:token" component={BureauFormView} />
      <Route path="/assign-password" component={AssignPasswordView} />
      <Route path="/user/forgot-password" component={RecoverPasswordView} />
      <Route path="/admin/forgot-password" component={RecoverPasswordView} />
      <Route path="/user/verification" component={UserVerificationView} />
      <Route path="/admin/verification" component={UserVerificationView} />
      <Route
        path="/user/recover-password"
        component={RecoverPasswordConfirmationView}
      />
      <Route
        path="/admin/recover-password"
        component={RecoverPasswordConfirmationView}
      />
      <PrivateRoute path="/" component={DashboardViews} />
    </Switch>
  </ConnectedRouter>
);

export default AppRoutes;
