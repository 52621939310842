import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadParameters,
  LOAD_PARAMETERS
} from 'src/store/actions/parameterActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { ParameterSection } from 'src/utils/constants/parameters';
import { selectParameterList } from 'src/store/selectors/parameterSelector';
import { useTranslation } from 'react-i18next';
import ParameterList from './ParameterList';
import NufiParameterList from './nufiParameter/NufiParameterList';
import BillingParameterList from './BillingParameter/BillingParameterList';
import MoffinParameterList from './moffinParameter/MoffinList';
import SigningParameterList from './SigningParameter/SigningParameterList';
import NotificationsParameterList from './NotificationsParameter/NotificationsList';
import BuroParamatersTabs from './BuroParameter/BuroParamatersTabs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row'
  }
}));

function ParameterTabs({ handleOpenEdit }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const parameters = useSelector(selectParameterList);
  const loading = useSelector(isLoadingSelector([LOAD_PARAMETERS.action]));

  const getParameterSection = useCallback(
    index => {
      switch (index) {
        case 0:
          return ParameterSection.GENERAL;
        case 1:
          return ParameterSection.NOTIFICATIONS;
        case 2:
          return ParameterSection.NUFI;
        case 3:
          return ParameterSection.BILLING;
        case 4:
          return ParameterSection.MOFFIN;
        case 5:
          return ParameterSection.SIGNING;
        case 6:
          return ParameterSection.BURO;
        default:
          return ParameterSection.GENERAL;
      }
    },
    [value]
  );

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      dispatch(loadParameters({ section: getParameterSection(newValue) }));
    },
    [setValue]
  );

  useEffect(() => {
    dispatch(loadParameters({ section: ParameterSection.GENERAL }));
  }, []);

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL']}
        current="NAVIGATION.PARAMETERS"
      />
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('PARAMETERS_VIEW.GENERAL')} />
          <Tab label={t('PARAMETERS_VIEW.NOTIFICATIONS')} />
          <Tab label={t('PARAMETERS_VIEW.NUFI')} />
          <Tab label={t('PARAMETERS_VIEW.BILLING')} />
          <Tab label={t('PARAMETERS_VIEW.MOFFIN')} />
          <Tab label={t('PARAMETERS_VIEW.SIGNING')} />
          <Tab label={t('PARAMETERS_VIEW.BURO')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotificationsParameterList parameters={parameters} loading={loading} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NufiParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BillingParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <MoffinParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <SigningParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <BuroParamatersTabs
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
    </div>
  );
}

ParameterTabs.propTypes = {
  handleOpenEdit: PropTypes.func
};

export default ParameterTabs;
