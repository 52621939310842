import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  IconButton,
  Grid,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { calculateFirstRentDateFromGracePeriod } from 'src/utils/contracts';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { UPDATE_CONTRACT_DATES } from 'src/store/actions/contractActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { getGracePeriodFromMonthDiff } from 'src/utils/contract';
import getMonthDiff from 'src/utils/date';
import useStyles from './styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import DialogTransition from '../../../../../components/DialogTransition';
import Loading from '../../../../../components/Loading';

const EditContractDateDialog = ({ open, contract, handleClose, submit }) => {
  const parameter = useSelector(selectDistributorDetail);
  const loading = useSelector(
    isLoadingSelector([UPDATE_CONTRACT_DATES.action])
  );
  const initialState = useMemo(() => makeInitialState(contract), [
    contract,
    parameter
  ]);
  const classes = useStyles();
  const resetRef = useRef();

  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const rawMonthDiff = getMonthDiff(values.startDate, values.firstRentDate);

      const gracePeriod = getGracePeriodFromMonthDiff(rawMonthDiff);

      submit({ ...values, gracePeriod });
      resetForm(initialState);
    },
    [submit, initialState, contract.id]
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-address-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {contract.isMsiModality
            ? t('CONTRACT_VIEW.CONTRACT_DETAIL.EDIT_CONTRACT_DATES_MSI')
            : t('CONTRACT_VIEW.CONTRACT_DETAIL.EDIT_CONTRACT_DATES')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    error={Boolean(
                      touched.firstRentDate && errors.firstRentDate
                    )}
                    fullWidth
                    helperText={
                      touched.firstRentDate && t(errors.firstRentDate)
                    }
                    label={t('CONTRACT_VIEW.PAY_DAY')}
                    margin="normal"
                    disabled
                    name="firstRentDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={selectFormatDate(
                      values.firstRentDate,
                      dateFormatType.ONLY_DAY
                    )}
                    variant="outlined"
                  />
                  <DatePicker
                    label={t('CONTRACT_VIEW.HOOK_DATE')}
                    inputFormat="DD/MM/YYYY"
                    value={values.startDate}
                    margin="startDate"
                    onChange={newValue => {
                      setFieldValue('startDate', momentLocalTimezone(newValue));
                      if (contract.isMsiModality) {
                        setFieldValue(
                          'firstRentDate',
                          momentLocalTimezone(newValue)
                        );
                        setFieldValue(
                          'endDate',

                          momentLocalTimezone(newValue).add(
                            values.term - 1,
                            'months'
                          )
                        );
                        return;
                      }
                      const firstRentDate = calculateFirstRentDateFromGracePeriod(
                        momentLocalTimezone(newValue),
                        values.gracePeriod
                      );
                      setFieldValue('firstRentDate', firstRentDate);

                      setFieldValue(
                        'endDate',
                        calculateFirstRentDateFromGracePeriod(
                          momentLocalTimezone(newValue),
                          values.gracePeriod
                        ).add(values.term - 1, 'months')
                      );
                    }}
                    error={Boolean(touched.startDate && errors.startDate)}
                    renderInput={params => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        {...params}
                        sx={{ width: '100%', margin: '16px 0' }}
                        FormHelperTextProps={{ sx: { color: 'red' } }}
                        helperText={touched.startDate && t(errors.startDate)}
                      />
                    )}
                  />

                  <DatePicker
                    label={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
                    value={values.firstRentDate}
                    margin="firstRentDate"
                    inputFormat="DD/MM/YYYY"
                    disabled={contract.isMsiModality}
                    onChange={newValue => {
                      setFieldValue(
                        'firstRentDate',
                        momentLocalTimezone(newValue)
                      );
                      setFieldValue(
                        'endDate',
                        momentLocalTimezone(newValue).add(
                          contract.term - 1,
                          'months'
                        )
                      );
                    }}
                    error={Boolean(
                      touched.firstRentDate && errors.firstRentDate
                    )}
                    renderInput={params => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        {...params}
                        sx={{ width: '100%', margin: '16px 0' }}
                        FormHelperTextProps={{ sx: { color: 'red' } }}
                        helperText={
                          touched.firstRentDate && t(errors.firstRentDate)
                        }
                      />
                    )}
                  />

                  <DatePicker
                    label={t('CONTRACT_VIEW.LAST_RENT_DATE')}
                    value={values.endDate}
                    margin="endDate"
                    inputFormat="DD/MM/YYYY"
                    disabled
                    error={Boolean(touched.endDate && errors.endDate)}
                    setValue={value => {
                      setFieldValue(
                        'endDate',
                        calculateFirstRentDateFromGracePeriod(
                          momentLocalTimezone(values.startDate),
                          value
                        ).add(values.term - 1, 'months')
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        InputProps={{
                          autoComplete: 'off'
                        }}
                        {...params}
                        sx={{ width: '100%', margin: '16px 0' }}
                        FormHelperTextProps={{
                          sx: {
                            color: 'red'
                          }
                        }}
                        helperText={touched.endDate && t(errors.endDate)}
                        error={Boolean(touched.endDate && errors.endDate)}
                      />
                    )}
                  />
                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

EditContractDateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  contract: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default EditContractDateDialog;
