import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import FailuresCategoriesView from './FailuresCategories';

const FailuresView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={FailuresCategoriesView} />
    </>
  );
};

FailuresView.propTypes = {
  match: PropTypes.object.isRequired
};

export default FailuresView;
