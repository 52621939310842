import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllKams } from 'src/store/actions/userKamActions';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import { DistributorRoles } from 'src/utils/constants/auth';
import ClientStatus from 'src/utils/constants/clients';
import ClientsRow from './ClientsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({
  clients,
  handleOpenConfirmation,
  isAdminView,
  isDistributorDirector,
  clientStatus
}) =>
  clients.map(client => (
    <ClientsRow
      key={client.id}
      isAdminView={isAdminView}
      {...client}
      handleOpenConfirmation={handleOpenConfirmation}
      isDistributorDirector={isDistributorDirector}
      clientStatus={clientStatus}
    />
  ));

const ClientsTable = ({
  className,
  clients,
  count,
  page,
  handleChangePage,
  loading,
  handleOpenConfirmation,
  isAdminView = true,
  ...rest
}) => {
  const { t } = useTranslation();
  const role = useSelector(selectAuthRole);
  const dispatch = useDispatch();
  const isDistributorDirector =
    !isAdminView && role === DistributorRoles.DIRECTOR;

  useEffect(() => {
    if (isDistributorDirector) {
      dispatch(loadAllKams(true));
    }
  }, [dispatch]);

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell key="id" style={{ width: '2%' }}>
        {t('COMMON.ID')}
      </TableCell>

      <TableCell variant="head" key="fullName" style={{ width: '3%' }}>
        {t('COMMON.FULL_NAME')}
      </TableCell>

      <TableCell
        variant="head"
        key="personType"
        align="center"
        style={{ width: '3%' }}
      >
        {t('COMMON.PERSON_TYPE')}
      </TableCell>

      <TableCell
        variant="head"
        key="mobile"
        align="center"
        style={{ width: '3%' }}
      >
        {t('COMMON.PHONE')}
      </TableCell>

      {!isAdminView && isDistributorDirector && (
        <>
          <TableCell variant="head" key="assignatedKam" style={{ width: '3%' }}>
            {t('CLIENTS_VIEW.CLIENT_DETAIL.ASSIGNATED_KAM')}
          </TableCell>
          <TableCell sx={{ width: '0%' }} variant="head" key="options">
            {' '}
          </TableCell>
        </>
      )}
      {isAdminView && (
        <>
          <TableCell
            variant="head"
            key="audit"
            align="center"
            style={{ width: '3%' }}
          >
            {t('COMMON.PENDING')}
          </TableCell>
          <TableCell
            variant="head"
            key="audit"
            align="center"
            style={{ width: '3%' }}
          >
            {t('COMMON.AVAL_PENDING')}
          </TableCell>
          <TableCell
            variant="head"
            key="validatedBilling"
            align="center"
            style={{ width: '5%' }}
          >
            {t('CLIENTS_VIEW.CLIENT_DETAIL.VALIDATED_BILLING')}
          </TableCell>
          {rest.clientStatus === ClientStatus.PROSPECT &&
            <TableCell
              variant="head"
              key="financingStatus"
              align="center"
              style={{ width: '5%' }}
            >
              {t('CLIENTS_VIEW.CLIENT_DETAIL.AUTHORIZATION')}
            </TableCell>}
          <TableCell
            variant="head"
            key="validatedBilling"
            align="left"
            style={{ width: '1%' }}
          >
            {' '}
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody
        clients={clients}
        handleOpenConfirmation={handleOpenConfirmation}
        isAdminView={isAdminView}
        isDistributorDirector={isDistributorDirector}
        clientStatus={rest.clientStatus}
      />
    ),
    [clients]
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, clients]
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

ClientsTable.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  handleOpenConfirmation: PropTypes.func,
  isAdminView: PropTypes.bool,
  isEditable: PropTypes.bool
};

ClientsTable.defaultProps = {
  count: 0
};

export default ClientsTable;
