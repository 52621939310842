import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';
import {
  LOAD_QUOTE_CATEGORIES,
  loadQuoteCategoriesList
} from 'src/store/actions/quoteActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getDifferenceBetweenStates } from 'src/utils/common';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import {
  LOAD_MSI_DISCOUNT,
  loadMsiDiscount
} from 'src/store/actions/catalogActions';
import { selectMsiDiscountsList } from 'src/store/selectors/catalogSelectors';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const EditParameterCategoryDialog = ({
  open,
  distributor,
  handleClose,
  onSubmit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(distributor), [
    distributor
  ]);
  const dispatch = useDispatch();
  const resetRef = useRef();
  const { t } = useTranslation();
  const loadingCategoriesList = useSelector(
    isLoadingSelector([LOAD_QUOTE_CATEGORIES.action])
  );
  const isLoadingDiscountMsi = useSelector(
    isLoadingSelector([LOAD_MSI_DISCOUNT.action])
  );
  const discountMsiList = useSelector(selectMsiDiscountsList);

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (open) {
      dispatch(loadQuoteCategoriesList(setCategoryList));
      if (distributor.enableMsi) {
        dispatch(loadMsiDiscount());
      }
    }
  }, [open]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      onSubmit(diference);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-quote-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DISTRIBUTORS_VIEW.PARAMETERS.EDIT_PARAMETER')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleSubmit,
            values,
            touched,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.quoteCategory && errors.quoteCategory)}
                >
                  <Autocomplete
                    options={categoryList}
                    id="quoteCategory"
                    loading={loadingCategoriesList}
                    getOptionLabel={option =>
                      option.name ? `${option.name}` : ''
                    }
                    label={t('DISTRIBUTORS_VIEW.PARAMETERS.CATEGORY')}
                    value={
                      categoryList.find(
                        category => category.id === values.quoteCategory?.id
                      ) ?? null
                    }
                    fullWidth
                    margin="normal"
                    onChange={(_e, value) => {
                      setFieldValue('quoteCategory', value ?? '');
                      setFieldValue('quoteCategoryId', value?.id ?? '');
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    error={Boolean(
                      touched.quoteCategory && errors.quoteCategory
                    )}
                    helperText={
                      touched.quoteCategory && t(errors.quoteCategory)
                    }
                  />
                </FormControl>

                {distributor.enableMsi && (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.discountMsi && errors.discountMsi)}
                  >
                    <Autocomplete
                      options={discountMsiList}
                      id="discountMsi"
                      loading={isLoadingDiscountMsi}
                      getOptionLabel={option =>
                        option.discountPercentage
                          ? `${option.discountPercentage}%`
                          : ''
                      }
                      label={t('DISTRIBUTORS_VIEW.PARAMETERS.MSI_DISCCOUNT')}
                      value={
                        discountMsiList.find(
                          discountMsi =>
                            discountMsi.id === values.discountMsi?.id
                        ) ?? null
                      }
                      fullWidth
                      margin="normal"
                      onChange={(_e, value) => {
                        setFieldValue('discountMsi', value ?? '');
                        setFieldValue('discountMsiId', value?.id ?? '');
                      }}
                      sx={{ marginBottom: 2, marginTop: 2 }}
                      error={Boolean(touched.discountMsi && errors.discountMsi)}
                      helperText={touched.discountMsi && t(errors.discountMsi)}
                    />
                  </FormControl>
                )}
                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button type="submit" color="primary" variant="outlined">
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditParameterCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  distributor: PropTypes.object
};

export default EditParameterCategoryDialog;
