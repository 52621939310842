import { asyncActionCreator } from 'src/utils/loadingUtils';
import getPaymentFailures from 'src/services/paymentService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { showMessage } from './snackBarActions';
import { selectPaymentQueryOptions } from '../selectors/paymentSelectors';

export const LOAD_PAYMENTS = asyncActionCreator('LOAD_PAYMENTS');
export const SAVE_PAYMENTS_OPTIONS = 'SAVE_PAYMENTS_OPTIONS';
export const CLEAR_PAYMENTS_OPTIONS = 'CLEAR_PAYMENT_OPTIONS';
export const LOAD_PAYMENTS_FAILS_LIST = asyncActionCreator(
  'LOAD_PAYMENT_FAILS_LIST'
);
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectPaymentQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_PAYMENTS_OPTIONS });
};

const loadPaymentFailures = options => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOAD_PAYMENTS_FAILS_LIST.start
    });

    dispatch({
      type: SAVE_PAYMENTS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const data = await getPaymentFailures(reqOptions);

    return dispatch({
      type: LOAD_PAYMENTS_FAILS_LIST.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_PAYMENTS_FAILS_LIST.failure });
  }
};

export default loadPaymentFailures;
