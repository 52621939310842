/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Button
} from '@mui/material';
import theme from 'src/theme';
import PercentageInputMask from 'src/components/inputMask/PercentageInputMask';
import {
  CaseOptions,
  CaseOptionsColors,
  generatePercentage,
  organizeData,
  reorganizeData
} from 'src/utils/constants/buroForm';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loading from 'src/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import {
  EDIT_BURO_FORM_PARAMETERS,
  loadParameters,
  updateBuroFormParameters
} from 'src/store/actions/parameterActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { ParameterSection } from 'src/utils/constants/parameters';

function BuroParameters({ loading, parameters }) {
  const dispatch = useDispatch();
  const [percentages, setPercentages] = useState(
    generatePercentage(parameters)
  );
  const loadingUpdate = useSelector(
    isLoadingSelector([EDIT_BURO_FORM_PARAMETERS.action])
  );

  const isLoading = loading || loadingUpdate;

  const { t } = useTranslation();

  const [headers] = useState([
    'Buro / Flujos',
    `<${percentages[0]}%`,
    `${percentages[0]}% - ${percentages[1]}%`,
    `>${percentages[1]}%`
  ]);

  const [data, setData] = useState(organizeData(parameters));

  const options = Object.values(CaseOptions);

  const handlePercentageChange = (index, event) => {
    const newPercentages = [...percentages];
    newPercentages[index] = event.target.value;
    if (event.target.value === '') {
      newPercentages[index] = 0;
    }
    if (index === 0 && Number(event.target.value) >= newPercentages[1]) {
      newPercentages[0] = newPercentages[1] - 1;
    }
    setPercentages(newPercentages);
  };

  const handleDataChange = (rowIndex, columnKey, event) => {
    const newData = [...data];
    newData[rowIndex][columnKey] = event.target.value;
    setData(newData);
  };

  const handleSave = useCallback(() => {
    const newData = reorganizeData(data, percentages);
    dispatch(updateBuroFormParameters(newData));
  }, [data, percentages]);

  const getBackgroundColor = value => {
    switch (value) {
      case CaseOptions.REVIEW:
        return CaseOptionsColors.REVIEW;
      case CaseOptions.DENIED:
        return CaseOptionsColors.DENIED;
      case CaseOptions.WITH_AVAL:
        return CaseOptionsColors.WITH_AVAL;
      case CaseOptions.WITHOUT_AVAL:
        return CaseOptionsColors.WITHOUT_AVAL;
      default:
        return '#FFFFFF'; // White
    }
  };

  useEffect(() => {
    setPercentages(generatePercentage(parameters));
    setData(organizeData(parameters));
  }, [parameters]);

  useEffect(() => {
    dispatch(loadParameters({ section: ParameterSection.BURO }));
  }, []);

  if (isLoading) {
    return (
      <Paper elevation={0} sx={{ padding: 2, boxShadow: 'none' }}>
        <Loading />
      </Paper>
    );
  }

  return (
    <Paper elevation={0} sx={{ padding: 2, boxShadow: 'none' }}>
      <Grid container spacing={0} sx={{ border: '1px solid black' }}>
        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid black',
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            padding: '16px',
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>
            {headers[0]}
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid black',
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            padding: '16px',
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: 'white', fontWeight: 'bold', marginRight: '4px' }}
          >
            {'<'}
          </Typography>
          <TextField
            value={percentages[0]}
            onChange={event => handlePercentageChange(0, event)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              inputComponent: PercentageInputMask,
              inputProps: {
                isAllowed: values => {
                  const { floatValue } = values;
                  return floatValue <= 100;
                }
              },
              sx: {
                padding: 0,
                marginLeft: '4px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'white',
                width: 40
              }
            }}
          />
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid black',
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            padding: '16px',
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TextField
            value={percentages[0]}
            onChange={event => handlePercentageChange(0, event)}
            variant="standard"
            InputProps={{
              inputComponent: PercentageInputMask,
              inputProps: {
                isAllowed: values => {
                  const { floatValue } = values;
                  return floatValue <= 100;
                }
              },
              disableUnderline: true,
              sx: {
                padding: 0,
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'white',
                width: 40
              }
            }}
          />
          <Typography
            variant="h5"
            sx={{ color: 'white', fontWeight: 'bold', margin: '0 4px' }}
          >
            -
          </Typography>
          <TextField
            value={percentages[1]}
            onChange={event => handlePercentageChange(1, event)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              inputComponent: PercentageInputMask,
              inputProps: {
                isAllowed: values => {
                  const { floatValue } = values;
                  return floatValue <= 100;
                }
              },
              sx: {
                padding: 0,
                marginLeft: '4px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'white',
                width: 40
              }
            }}
          />
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            border: '1px solid black',
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            padding: '16px',
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: 'white', fontWeight: 'bold', marginRight: '4px' }}
          >
            {'>'}
          </Typography>
          <TextField
            value={percentages[1]}
            onChange={event => handlePercentageChange(1, event)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              inputComponent: PercentageInputMask,
              inputProps: {
                isAllowed: values => {
                  const { floatValue } = values;
                  return floatValue <= 100;
                }
              },
              sx: {
                padding: 0,
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'white',
                marginLeft: '4px',
                width: 40
              }
            }}
          />
        </Grid>

        {/* Render Rows */}
        {data.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {/* Editable Range Input */}
            <Grid
              item
              xs={3}
              sx={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '16px',
                minHeight: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextField
                value={row.range}
                onChange={event => handleDataChange(rowIndex, 'range', event)}
                variant="standard"
                type="number"
                disabled={rowIndex === 0 || rowIndex === data.length - 1}
                InputProps={{
                  disableUnderline: true,

                  sx: {
                    textAlign: 'center',
                    padding: '8px',
                    width: 60
                  }
                }}
              />
            </Grid>
            {['firstCase', 'secondCase', 'thirdCase'].map(
              (columnKey, colIndex) => (
                <Grid
                  item
                  xs={3}
                  key={colIndex}
                  sx={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: getBackgroundColor(row[columnKey]),
                    padding: '16px',
                    minHeight: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Select
                    value={row[columnKey]}
                    onChange={event =>
                      handleDataChange(rowIndex, columnKey, event)
                    }
                    fullWidth
                    variant="standard"
                    disableUnderline
                    sx={{
                      textAlign: 'center',
                      padding: '8px 0',
                      '& .MuiSelect-select': {
                        padding: '8px 0',
                        textAlign: 'center'
                      },
                      '& .MuiSelect-icon': {
                        display: 'none'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      }
                    }}
                  >
                    {options.map(option => (
                      <MenuItem key={option} value={option}>
                        {t(`COMMON.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )
            )}
          </React.Fragment>
        ))}
      </Grid>
      <Button
        onClick={handleSave}
        disabled={isLoading}
        variant="contained"
        color="primary"
        sx={{ marginTop: '16px' }}
      >
        {t('COMMON.SAVE')}
      </Button>
    </Paper>
  );
}

BuroParameters.propTypes = {
  loading: PropTypes.bool,
  parameters: PropTypes.array
};

export default BuroParameters;
