import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  CLEAR_DISTRIBUTORS_CATALOGS,
  getDistributorParameters,
  LOAD_DISTRIBUTOR_CATALOGS,
  loadDistributorEquipmentsCatalog
} from 'src/store/actions/distributorActions';
import {
  selectDistributorCatalogs,
  selectDistributorCatalogsCount,
  selectDistributorDetail,
  selectDistributorQueryOptions
} from 'src/store/selectors/distributorSelectors';
import { useParams } from 'react-router';
import { Card } from '@mui/material';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CatalogsTable from './CatalogsTable';

const CatalogsList = ({ isDisableActions }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const catalogs = useSelector(selectDistributorCatalogs);
  const { page } = useSelector(selectDistributorQueryOptions);
  const count = useSelector(selectDistributorCatalogsCount);

  const loading = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );
  const success = useSelector(
    successSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadDistributorEquipmentsCatalog(id, { page: value }));
    },
    [dispatch]
  );
  const distributor = useSelector(selectDistributorDetail);

  useEffect(() => {
    dispatch(loadDistributorEquipmentsCatalog(id));
    dispatch(getDistributorParameters(id));
    return () => dispatch({ type: CLEAR_DISTRIBUTORS_CATALOGS });
  }, []);

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.DISTRIBUTOR_CATALOGS_TITLE')}
        />
      </Card>
    );
  }

  return (
    <CatalogsTable
      catalogs={catalogs}
      loading={loading}
      handleChangePage={handleChangePage}
      page={page}
      count={count}
      isDisableActions={isDisableActions}
      isMsiEnabledForDistributor={distributor.enableMsi}
    />
  );
};

CatalogsList.propTypes = {
  isDisableActions: PropTypes.bool
};

export default CatalogsList;
