import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';

const DiscountGridRow = ({
  initialPayment,
  discountTwelve,
  discountEighteen,
  discountTwentyFour
}) => {
  return (
    <TableRow hover>
      <TableCell>{`${initialPayment}%`}</TableCell>
      <TableCell align="center">{`${discountTwelve}%`}</TableCell>
      <TableCell align="center">{`${discountEighteen}%`}</TableCell>
      <TableCell align="center">{`${discountTwentyFour}%`}</TableCell>
    </TableRow>
  );
};

DiscountGridRow.propTypes = {
  initialPayment: PropTypes.number.isRequired,
  discountTwelve: PropTypes.number.isRequired,
  discountEighteen: PropTypes.number.isRequired,
  discountTwentyFour: PropTypes.number.isRequired
};

export default DiscountGridRow;
