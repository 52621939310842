import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import useStyles from './styles';
import DiscountGridRow from './DiscountGridRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ gridData }) =>
  gridData.map(item => <DiscountGridRow key={item.initialPayment} {...item} />);

const DiscountGridTable = ({ gridData, loading, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="name" sx={{ width: '20%' }}>
          {`${t('COMMON.INITIAL_PAYMENT')} (%)`}
        </TableCell>
        <TableCell
          variant="head"
          key="twelveMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.TWELVE_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="eighteenMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.EIGHTEEN_MONTHS')}
        </TableCell>
        <TableCell
          variant="head"
          key="twentyFourMonthsRate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.MONTH_RATE.TWENTY_FOUR_MONTHS')}
        </TableCell>
      </TableRow>
    ),
    [classes]
  );

  const tbody = useMemo(() => () => <Tbody t={t} gridData={gridData} />, [
    gridData,
    t
  ]);

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

DiscountGridTable.propTypes = {
  gridData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DiscountGridTable;
