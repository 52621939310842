import { selectAddressByType } from 'src/utils/address';
import { hasAddressCompleted } from 'src/utils/common';
import { AddressType } from 'src/utils/constants/address';

const makeInitialState = values => {
  const workAddress =
    selectAddressByType(values.client?.addresses || [], AddressType.WORK) || {};
  return {
    quoteId: null,
    startDate: values.initialPaymentDate || null,
    firstRentDate: values.firstRentDate || null,
    endDate: null,
    term: values ? values.term : null,
    initialPayment: values ? values.initialPayment : null,
    sendToDigitalSign: false,
    equipmentLocation: {
      street: workAddress.street,
      insideHomeNumber: workAddress.insideHomeNumber,
      externalHomeNumber: workAddress.externalHomeNumber,
      colonia: workAddress.colonia,
      city: workAddress.city,
      state: workAddress.state,
      zipCode: workAddress.zipCode
    },
    isExistingAddress: hasAddressCompleted(workAddress),
    clientId: values ? values.client?.id : null,
    clientPersonType: values ? values.client?.personType : null,
    bankInformation:
      values &&
      values.client?.bankName &&
      (values.client?.bankAccount || values.client?.clabe)
        ? {
            bankName: values.client?.bankName,
            bankAccount: values.client?.bankAccount,
            clabe: values.client?.clabe
          }
        : {},
    gracePeriod: values.gracePeriod != null ? Number(values.gracePeriod) : null,
    isQuoteAssignedToClient: Boolean(values.client?.id) || false,
    payday: values.payday || null,
    isMsiModality: values.isMsiModality || false,
  };
};

export default makeInitialState;
