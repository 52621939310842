import { removesScientificNotationFromNumber } from 'src/utils/formatAmout';

export default values => ({
  ...values,
  rate: values.rate ? removesScientificNotationFromNumber(values.rate) : null,
  initialPaymentPorcentaje: values.initialPaymentPorcentaje || null,
  term: values.term || null,
  equipmentCost: values.equipmentCost || null,
  initialPayment: values.initialPayment || null,
  netInvestment: values.netInvestment || 0,
  rent: values.rent || 0,
  residualPayment: values.residualPayment || 1,
  enableMsi: values.enableMsi || false
});
