export const selectPayments = state => state.payments;
export const selectPaymentsList = state => selectPayments(state).items;
export const selectPaymentsListCount = state => selectPayments(state).count;
export const selectPaymentQueryOptions = state => {
  const { options } = selectPayments(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
