import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  SIGN_CONTRACT_MANUALLY,
  signContractManuallyAction
} from 'src/store/actions/contractActions';
import {
  RESEND_SIGN_EMAIL,
  resendSignEmailAction
} from 'src/store/actions/commonActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import React, { useCallback, useState } from 'react';
import DocumentListModal from 'src/components/DocumentListModal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContractStatus, {
  ContractActionsOptions
} from 'src/utils/constants/contracts';
import { Send } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddDocumentModal from 'src/components/AddDocumentModal/AddDocumentModal';
import SignContractManuallyDialog from 'src/views/contract/ContractDetailView/GeneralDetail/SignContractManuallyDialog';

export const ContractActionMenuOptions = [
  {
    label: 'CONTRACT_VIEW.CONTRACT_DETAIL.SIGN_MANUALLY',
    key: ContractActionsOptions.SIGN_MANUALLY,
    icon: <Send />
  },
  {
    label: 'COMMON.RESEND_SIGNING_URL',
    key: ContractActionsOptions.RESEND_TO_SIGN,
    icon: <RefreshIcon />
  }
];

const modalType = {
  ADD: 'ADD',
  HISTORY: 'HISTORY'
};

const DocumentsContractMenu = ({
  documentType,
  hideMoreIcon,
  contractId,
  contract,
  promissoryNoteId,
  documents,
  isDisabledEdit = false,
  isDisabledAdd = false
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorHistoryModal, setAnchorHistoryModal] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openSignManuallyDialog, setOpenSignManuallyDialog] = useState(false);

  const handleOpenSignManuallyDialog = () => {
    setOpenSignManuallyDialog(true);
  };

  const handleCloseSignManuallyDialog = () => {
    setOpenSignManuallyDialog(false);
  };

  const handleContractManualSigning = useCallback(values => {
    dispatch(signContractManuallyAction(id, values));
  }, []);

  const handleResendSignEmail = useCallback(() => {
    dispatch(resendSignEmailAction(contract.weetrustDocId));
  }, [contract]);

  const handleContractAction = action => {
    switch (action) {
      case ContractActionsOptions.SIGN_MANUALLY:
        handleOpenSignManuallyDialog();
        break;
      case ContractActionsOptions.RESEND_TO_SIGN:
        handleResendSignEmail();
        break;
      default:
        break;
    }
  };

  const selectContractActionsFromStatus = () => {
    let actions = [];

    switch (contract.status) {
      case ContractStatus.DRAFT:
        actions = ContractActionMenuOptions.filter(item =>
          [ContractActionsOptions.SIGN_MANUALLY].includes(item.key)
        );
        break;
      case ContractStatus.PENDING_SIGNATURE:
        actions = ContractActionMenuOptions.filter(item => {
          return [ContractActionsOptions.RESEND_TO_SIGN].includes(item.key);
        });
        break;
      default:
        break;
    }

    return actions;
  };

  const isLoadingAction = useSelector(
    isLoadingSelector([SIGN_CONTRACT_MANUALLY.action, RESEND_SIGN_EMAIL.action])
  );

  const handleOpenModal = useCallback((e, type) => {
    switch (type) {
      case modalType.ADD:
        setOpenAddModal(documentType);
        handleCloseMenu();
        break;
      case modalType.HISTORY:
        setAnchorHistoryModal(documentType);
        handleCloseMenu();
        break;
      default:
        break;
    }
  }, []);

  const handleCloseModal = type => {
    switch (type) {
      case modalType.ADD:
        setOpenAddModal(false);
        break;
      case modalType.HISTORY:
        setAnchorHistoryModal(null);
        break;
      default:
        break;
    }
  };

  return (
    <Box component="div">
      <IconButton
        disabled={isDisabledEdit}
        aria-label="documentsMenu"
        id="documentsMenu"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        sx={{ display: hideMoreIcon && 'none' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={documentType}
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            width: '27ch',
            bgcolor: 'background.paper',
            color: 'text.primary',
            boxShadow: 1
          }
        }}
      >
        {!isDisabledAdd && (
          <MenuItem
            onClick={e => handleOpenModal(e, modalType.ADD)}
            disabled={isDisabledAdd}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {documents
                ? t('COMMON.UPDATE_DOCUMENT')
                : t('COMMON.ADD_DOCUMENT')}
            </Typography>
          </MenuItem>
        )}
        {!isDisabledAdd &&
          selectContractActionsFromStatus().map(action => (
            <MenuItem
              key={action.key}
              onClick={() => handleContractAction(action.key)}
              disabled={isLoadingAction}
            >
              <ListItemIcon>
                {action.key === ContractActionsOptions.RESEND_TO_SIGN &&
                isLoadingAction ? (
                  <CircularProgress size={20} />
                ) : (
                  action.icon
                )}
              </ListItemIcon>
              <Typography variant="inherit">{t(action.label)}</Typography>
            </MenuItem>
          ))}
        <MenuItem
          onClick={e => handleOpenModal(e, modalType.HISTORY)}
          disabled={!documents}
        >
          <ListItemIcon>
            <ListAltIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t('COMMON.HISTORY')}</Typography>
        </MenuItem>
      </Menu>

      <DocumentListModal
        title={documentType}
        open={anchorHistoryModal}
        promissoryNoteId={promissoryNoteId}
        contractId={contractId}
        documentType={documentType}
        handleClose={() => handleCloseModal(modalType.HISTORY)}
      />

      <AddDocumentModal
        open={openAddModal}
        documentType={documentType}
        contractId={contractId}
        promissoryNoteId={promissoryNoteId}
        handleClose={() => handleCloseModal(modalType.ADD)}
        documents={documents}
      />

      <SignContractManuallyDialog
        open={openSignManuallyDialog}
        handleClose={handleCloseSignManuallyDialog}
        submit={handleContractManualSigning}
      />
    </Box>
  );
};

DocumentsContractMenu.propTypes = {
  documentType: PropTypes.string.isRequired,
  documents: PropTypes.bool,
  contractId: PropTypes.number,
  contract: PropTypes.any,
  promissoryNoteId: PropTypes.number,
  isDisabledEdit: PropTypes.bool,
  isDisabledAdd: PropTypes.bool,
  hideMoreIcon: PropTypes.bool
};

export default DocumentsContractMenu;
