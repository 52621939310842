import React, { useCallback, useState } from 'react';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  deleteDistributorCatalog,
  updateDistributorCatalog
} from 'src/store/actions/distributorActions';
import { useDispatch } from 'react-redux';
import IconStatusAndColor from 'src/utils/constants/common';
import StatusIcon from 'src/components/StatusIcon';
import {
  DistributorEquipmentMenuOptions,
  getMenuOptionsList
} from 'src/utils/distributorEquipment';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import CatalogDialog from './CreateCatalog';
import DeleteDistributorCatalogDialog from './DeleteDistributorCatalogDialog/DeleteDistributorCatalogDialog';

const CatalogsRow = ({
  id,
  brand,
  model,
  price,
  iva,
  priceWithIva,
  isMovable,
  isDisableActions,
  enableMsi,
  isMsiEnabledForDistributor
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditMsiDialog, setOpenEditMsiDialog] = useState('');
  const { id: distributorId } = useParams();

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseEnableMsi = () => {
    setOpenEditMsiDialog('');
  };

  const handleClickMore = option => {
    if (option === DistributorEquipmentMenuOptions.EDIT) {
      setOpenEditDialog(true);
    }
    if (option === DistributorEquipmentMenuOptions.DELETE) {
      setOpenDeleteDialog(true);
    }
    if (option === DistributorEquipmentMenuOptions.ENABLE_MSI) {
      setOpenEditMsiDialog(DistributorEquipmentMenuOptions.ENABLE_MSI);
    }
    if (option === DistributorEquipmentMenuOptions.DISABLE_MSI) {
      setOpenEditMsiDialog(DistributorEquipmentMenuOptions.DISABLE_MSI);
    }
  };

  const handleSubmitDelete = useCallback(() => {
    dispatch(deleteDistributorCatalog(id, distributorId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  const handleSubmitEnableMsi = useCallback(() => {
    dispatch(updateDistributorCatalog(id, distributorId, { enableMsi: true }));
    setOpenEditMsiDialog(false);
  }, [handleCloseDelete]);

  const handleSubmitDisableMsi = useCallback(() => {
    dispatch(updateDistributorCatalog(id, distributorId, { enableMsi: false }));
    setOpenEditMsiDialog(false);
  }, [handleCloseDelete]);

  const handleUpdateContact = useCallback(
    values => {
      dispatch(updateDistributorCatalog(id, distributorId, values));
      handleCloseEdit();
    },
    [dispatch, id]
  );

  const selectEnableMsiIcon = () => {
    if (enableMsi) {
      return (
        <StatusIcon
          description="COMMON.MSI_ENABLED"
          type={IconStatusAndColor.OK.status}
        />
      );
    }

    return (
      <StatusIcon
        description="COMMON.MSI_DISABLED"
        type={IconStatusAndColor.ERROR.status}
      />
    );
  };

  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell align="center">
        {brand ? brand.name : t('COMMON.NO_DATA')}
      </TableCell>
      <TableCell align="center">{model}</TableCell>
      <TableCell align="center">{formatPositiveAmount(price)}</TableCell>
      <TableCell align="center">{formatPositiveAmount(iva)}</TableCell>
      <TableCell align="center">{formatPositiveAmount(priceWithIva)}</TableCell>
      <TableCell align="center">
        {isMovable ? t('COMMON.YES') : t('COMMON.NO')}
      </TableCell>
      {isMsiEnabledForDistributor && (
        <TableCell align="center">{selectEnableMsiIcon()}</TableCell>
      )}
      <TableCell align="right">
        <MoreMenu
          options={getMenuOptionsList(enableMsi, isMsiEnabledForDistributor)}
          handleClickOption={handleClickMore}
          disabled={isDisableActions}
        />
      </TableCell>
      <CatalogDialog
        open={openEditDialog}
        handleClose={handleCloseEdit}
        onSubmit={handleUpdateContact}
        catalog={{
          id,
          brand,
          model,
          price,
          iva,
          priceWithIva,
          isMovable
        }}
      />
      <DeleteDistributorCatalogDialog
        open={openDeleteDialog}
        handleClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
      <ConfirmationDialog
        open={openEditMsiDialog === DistributorEquipmentMenuOptions.ENABLE_MSI}
        handleClose={handleCloseEnableMsi}
        onSubmit={handleSubmitEnableMsi}
        title={t('COMMON.ENABLE_MSI')}
        description={t('COMMON.ENABLE_MSI_CONFIRMATION_DESCRIPTION')}
      />
      <ConfirmationDialog
        open={openEditMsiDialog === DistributorEquipmentMenuOptions.DISABLE_MSI}
        handleClose={handleCloseEnableMsi}
        onSubmit={handleSubmitDisableMsi}
        title={t('COMMON.DISABLE_MSI')}
        description={t('COMMON.DISABLE_MSI_CONFIRMATION_DESCRIPTION')}
      />
    </TableRow>
  );
};

CatalogsRow.propTypes = {
  id: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  iva: PropTypes.string.isRequired,
  priceWithIva: PropTypes.string.isRequired,
  isMovable: PropTypes.string.isRequired,
  isDisableActions: PropTypes.bool.isRequired,
  enableMsi: PropTypes.bool.isRequired,
  isMsiEnabledForDistributor: PropTypes.bool.isRequired
};

export default CatalogsRow;
