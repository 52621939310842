import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const getPaymentFailures = serviceBase({
  request: options =>
    axiosClient.get('automatic-debit', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export default getPaymentFailures;
