import React, { useCallback, useState } from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useDispatch } from 'react-redux';
import { updateContract } from 'src/store/actions/contractActions';
import PayDayDialog from './PayDayDialog';

const ContractDateDetail = ({ contract }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickMore = () => setOpenDialog(true);
  const handleCloseEdit = () => {
    setOpenDialog('');
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(
        updateContract(
          contract.id,
          {
            payday: values.payday
          },
          handleCloseEdit
        )
      );
    },
    [contract.id, dispatch]
  );
  return (
    <>
      <DetailRow name={t('CONTRACT_VIEW.PAY_DAY')} value={contract.payday}>
        <MoreMenu deleteDisabled handleClickOption={handleClickMore} />
      </DetailRow>

      <PayDayDialog
        open={openDialog}
        handleClose={handleCloseEdit}
        submit={handleSubmit}
        payDayData={contract.payday}
      />
      <DetailRow
        name={t('CONTRACT_VIEW.HOOK_DATE')}
        value={selectFormatDate(contract.startDate, dateFormatType.DAY)}
      />

      <DetailRow
        name={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
        value={selectFormatDate(contract.firstRentDate, dateFormatType.DAY)}
      />

      <DetailRow
        name={t('CONTRACT_VIEW.LAST_RENT_DATE')}
        value={selectFormatDate(contract.endDate, dateFormatType.DAY)}
      />

      {contract.gracePeriod > 0 && (
        <DetailRow
          name={t('COMMON.DAYS_FOR_FIRST_RENT')}
          value={translateGracePeriodValueToDaysForFirstRent(
            contract.gracePeriod,
            t
          )}
        />
      )}
    </>
  );
};

ContractDateDetail.propTypes = {
  contract: PropTypes.object.isRequired
};
export default ContractDateDetail;
