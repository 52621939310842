import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/components/PrivateRotes/PrivateRoute';
import BillingListView from './BillingList';

const BillingView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}/list`} component={BillingListView} />
    </>
  );
};

BillingView.propTypes = {
  match: PropTypes.object.isRequired
};

export default BillingView;
