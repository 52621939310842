export default values => ({
  minTerm: values.minTerm || '',
  maxTerm: values.maxTerm || '',
  isPriceEditable: values.isPriceEditable || false,
  minInitialPayment: values.minInitialPayment || '',
  isInsuranceIncluded: values.isInsuranceIncluded || false,
  maxGracePeriod: values.maxGracePeriod || '',
  defaultGracePeriod: values.defaultGracePeriod || '',
  enableUseKamContactInfo: values.enableUseKamContactInfo || false,
  enableEditKamInfo: values.enableEditKamInfo || false,
  enableMsi: values.enableMsi || false,
  maxResidualValue: values.maxResidualValue || ''
});
