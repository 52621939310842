import React from 'react';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import MsiDiscountList from './MsiDiscountList';
import useStyles from './styles';

const MsiDiscountListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Page className={classes.root} title={t('ENUMS.QUOTERS.MSI_RATE')}>
      <FadeIn transitionDuration={200}>
        <Toolbar />
        <MsiDiscountList />
      </FadeIn>
    </Page>
  );
};

export default MsiDiscountListView;
