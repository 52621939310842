import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  LOAD_MSI_DISCOUNT,
  loadMsiDiscount
} from 'src/store/actions/catalogActions';
import { selectMsiDiscountList } from 'src/store/selectors/catalogSelectors';
import DiscountMsiTable from './DiscountMsiTable';

const MsiDiscountList = () => {
  const dispatch = useDispatch();
  const msiDiscount = useSelector(selectMsiDiscountList);
  const loading = useSelector(isLoadingSelector([LOAD_MSI_DISCOUNT.action]));
  const success = useSelector(successSelector([LOAD_MSI_DISCOUNT.action]));

  useEffect(() => {
    dispatch(loadMsiDiscount());
  }, []);

  return (
    <Box mt={1}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <DiscountMsiTable
            msiDiscount={msiDiscount}
            loading={loading}
            success={success}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MsiDiscountList;
