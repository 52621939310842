import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttons: {
    height: 50,
    letterSpacing: 1,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset'
  },
  outlined: {
    background: theme.palette.common.white,
    fontWeight: '500',
    '&:hover': {
      background: theme.palette.common.white,
      opacity: 0.8
    },
    '&:focus': {
      background: theme.palette.common.white,
      opacity: 0.8
    }
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  input: {
    width: '100%'
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3)
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8
    }
  },
  pendingFieldCell: {
    borderRadius: '4px',
    textAlign: 'center',
    width: '30px',
    height: '30px',
    padding: 'auto',
    color: theme.palette.secondary.main
  },
  dialogRoot: {
    width: theme.spacing(60)
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400
    }
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px'
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  textField: {
    backgroundColor: 'white'
  }
}));
