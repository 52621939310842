import React from 'react';
import PropTypes from 'prop-types';
import DetailRow from 'src/components/DetailRow';
import { Link } from 'react-router-dom';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { Card, CardContent, Table, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FinancingStatusQuoteDetail = ({ quote }) => {
  const { t } = useTranslation();

  return (
    <Card variant="elevation">
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <DetailRow
              name={t('CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_QUOTE.QUOTE_ID')}
              value={
                quote?.id ? (
                  <Link
                    to={`/quotes/detail/${quote.id}`}
                    style={{ textDecoration: 'none', color: '#1a0dab' }}
                  >
                    {quote.id}
                  </Link>
                ) : (
                  t('COMMON.NO_DATA')
                )
              }
            />
            <DetailRow
              name={t('CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_QUOTE.QUOTE_PRICE')}
              value={
                quote?.price
                  ? formatPositiveAmount(quote?.price)
                  : t('COMMON.NO_DATA')
              }
            />
            <DetailRow
              name={t('CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_QUOTE.QUOTE_TERM')}
              value={quote?.term ? `${quote?.term} meses` : t('COMMON.NO_DATA')}
            />
            <DetailRow
              name={t(
                'CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_QUOTE.QUOTE_EQUIPMENT_BRAND'
              )}
              value={
                quote?.distributorEquipment.brand.name || t('COMMON.NO_DATA')
              }
            />
            <DetailRow
              name={t(
                'CLIENTS_VIEW.CLIENT_DETAIL.FINANCING_QUOTE.QUOTE_EQUIPMENT_MODEL'
              )}
              value={quote?.distributorEquipment.model || t('COMMON.NO_DATA')}
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

FinancingStatusQuoteDetail.propTypes = {
  quote: PropTypes.object.isRequired
};

export default FinancingStatusQuoteDetail;
