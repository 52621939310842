import {
  createAgent,
  createBrand,
  createCompany,
  createIncident,
  createService,
  createInsurer,
  deleteBrand,
  deleteCompany,
  deleteIncident,
  deleteService,
  editBrand,
  editCompany,
  editIncident,
  editService,
  getAgents,
  getBrands,
  getCompanies,
  getIncidents,
  getServices,
  getInsurers,
  getTaxSystems,
  getEquipmentTypes,
  editEquipmentType,
  deleteEquipmentType,
  getBillingConceptList,
  createDenomination,
  getDenominations,
  editDenomination,
  deleteDenomination,
  getModels,
  createModel,
  deleteModel,
  editModel,
  getKinships,
  createKinhip,
  editKinship,
  deleteKinship,
  getDistributorBrands,
  getDistributorModels,
  getIndustries,
  createIndustry,
  editIndustry,
  deleteIndustry,
  createAdministrativeRole,
  getAdministrativeRoles,
  editAdministrativeRole,
  deleteAdministrativeRole,
  getEconomicActivities,
  createEconomicActivity,
  editEconomicActivity,
  deleteEconomicActivity,
  getBanks,
  createBank,
  editBank,
  deleteBank,
  createLineOfBusiness,
  editLineOfBusiness,
  deleteLineOfBusiness,
  getLineOfBusiness,
  deleteMsiDiscount,
  getMsiDiscount,
  editMsiDiscount,
  createMsiDiscount,
  calculateDiscountGrid
} from 'src/services/catalogService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import UserType from 'src/utils/constants/auth';
import { showMessage } from './snackBarActions';
import { createEquipmentType } from '../../services/catalogService';

export const LOAD_CATALOG = asyncActionCreator('LOAD_CATALOG');
export const LOAD_AGENTS = asyncActionCreator('LOAD_AGENTS');
export const LOAD_INSURERS = asyncActionCreator('LOAD_INSURERS');
export const LOAD_EXPENSE_TYPES = asyncActionCreator('LOAD_EXPENSE_TYPES');
export const LOAD_BRANDS = asyncActionCreator('LOAD_BRANDS');
export const LOAD_BRANDS_LIST = asyncActionCreator('LOAD_BRANDS_LIST');
export const LOAD_TAX_SYSTEM = asyncActionCreator('LOAD_TAX_SYSTEM');
export const LOAD_EQUIPMENT_TYPES = asyncActionCreator('LOAD_EQUIPMENT_TYPES');
export const LOAD_DENOMINATIONS = asyncActionCreator('LOAD_DENOMINATIONS');
export const LOAD_BILLING_CONCEPT = asyncActionCreator('LOAD_BILLING_CONCEPT');
export const LOAD_MODELS = asyncActionCreator('LOAD_MODELS');
export const CREATE_MODEL = asyncActionCreator('CREATE_MODEL');
export const CREATE_BRAND = asyncActionCreator('CREATE_BRAND');
export const DELETE_BRAND = asyncActionCreator('DELETE_BRAND');
export const EDIT_MODEL = asyncActionCreator('EDIT_MODEL');
export const DELETE_MODEL = asyncActionCreator('DELETE_MODEL');
export const LOAD_KINSHIPS = asyncActionCreator('LOAD_KINSHIPS');
export const CREATE_KINSHIP = asyncActionCreator('CREATE_KINSHIP');
export const EDIT_KINSHIP = asyncActionCreator('EDIT_KINSHIP');
export const DELETE_KINSHIP = asyncActionCreator('DELETE_KINSHIP');
export const LOAD_LINE_OF_BUSINESS = asyncActionCreator(
  'LOAD_LINE_OF_BUSINESS'
);
export const CREATE_LINE_OF_BUSINESS = asyncActionCreator(
  'CREATE_LINE_OF_BUSINESS'
);
export const EDIT_LINE_OF_BUSINESS = asyncActionCreator(
  'EDIT_LINE_OF_BUSINESS'
);
export const DELETE_LINE_OF_BUSINESS = asyncActionCreator(
  'DELETE_LINE_OF_BUSINESS'
);
export const LOAD_INDUSRTIES = asyncActionCreator('LOAD_INDUSTRIES');
export const LOAD_MSI_DISCOUNT = asyncActionCreator('LOAD_MSI_DISCOUNT');
export const CREATE_INDUSTRY = asyncActionCreator('CREATE_INDUSTRY');
export const EDIT_INDUSTRY = asyncActionCreator('EDIT_INDUSTRY');
export const DELETE_INDUSTRY = asyncActionCreator('DELETE_INDUSTRY');
export const LOAD_KINSHIP_LIST = asyncActionCreator('LOAD_KINSHIP_LIST');
export const LOAD_INDUSTRY_LIST = asyncActionCreator('LOAD_INDUSTRY_LIST');
export const LOAD_MSI_DISCOUNT_LIST = asyncActionCreator(
  'LOAD_MSI_DISCOUNT_LIST'
);
export const LOAD_ADMINISTRATIVE_ROLES = asyncActionCreator(
  'LOAD_ADMINISTRATIVE_ROLES'
);
export const CLEAR_CATALOG = 'CLEAR_CATALOG';
export const SET_MODEL_NAME = 'SET_MODEL_NAME';
export const EDIT_BRAND = asyncActionCreator('EDIT_BRAND');
export const SET_DISTRIBUTOR_ID = 'SET_DISTRIBUTOR_ID';
export const CLEAR_DISTRIBUTOR_ID = 'CLEAR_DISTRIBUTOR_ID';
// Economic Activities
export const LOAD_ECONOMIC_ACTIVITIES = asyncActionCreator(
  'LOAD_ECONOMIC_ACTIVITIES'
);
export const CREATE_ECONOMIC_ACTIVITY = asyncActionCreator(
  'CREATE_ECONOMIC_ACTIVITY'
);
export const EDIT_ECONOMIC_ACTIVITY = asyncActionCreator(
  'EDIT_ECONOMIC_ACTIVITY'
);
export const DELETE_ECONOMIC_ACTIVITY = asyncActionCreator(
  'DELETE_ECONOMIC_ACTIVITY'
);
// Banks
export const CREATE_BANK = asyncActionCreator('CREATE_BANK');
export const EDIT_BANK = asyncActionCreator('EDIT_BANK');
export const DELETE_BANK = asyncActionCreator('DELETE_BANK');

export const CALCULATE_DISCOUNT_GRID = asyncActionCreator(
  'CALCULATE_DISCOUNT_GRID'
);
export const CLEAR_DISCOUNT_GRID = 'CLEAR_DISCOUNT_GRID';

export const loadCatalog = service => () => async dispatch => {
  try {
    dispatch({ type: LOAD_CATALOG.start });

    const data = await service();

    return dispatch({
      type: LOAD_CATALOG.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CATALOG.failure });
  }
};

export const clearCatalog = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_CATALOG });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadAgents = loadCatalog(getAgents);
export const loadCompanies = loadCatalog(getCompanies);
export const loadEquipmentTypes = loadCatalog(getEquipmentTypes);
export const loadDenominations = loadCatalog(getDenominations);
export const loadServices = loadCatalog(getServices);
export const loadIncidents = loadCatalog(getIncidents);
export const loadInsurers = loadCatalog(getInsurers);
export const loadTaxSystems = loadCatalog(getTaxSystems);
export const loadAdministrativesRoles = loadCatalog(getAdministrativeRoles);
export const loadEconomicActivities = loadCatalog(getEconomicActivities);

export const loadInsurersList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_INSURERS.start });

    const data = await getInsurers();

    return dispatch({
      type: LOAD_INSURERS.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INSURERS.failure });
  }
};

export const loadBrandsList = (
  setState = () => {},
  loadAllBrands = false
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_BRANDS_LIST.start });

    const { system, distributorId } = getState().auth;
    const { id: distributorIdByView } = getState().distributor.detail;

    let data = {};

    if (loadAllBrands) {
      data = await getBrands();
    } else if (
      system === UserType.DISTRIBUTOR ||
      distributorId ||
      distributorIdByView
    ) {
      data = await getDistributorBrands(distributorId || distributorIdByView);
    } else {
      data = await getBrands();
    }

    setState(data);
    return dispatch({
      type: LOAD_BRANDS_LIST.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_BRANDS_LIST.failure });
  }
};

export const loadAgentsList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_AGENTS.start });

    const data = await getAgents();

    return dispatch({
      type: LOAD_AGENTS.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AGENTS.failure });
  }
};

export const loadTaxSystemList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_TAX_SYSTEM.start });

    const data = await getTaxSystems();

    return dispatch({
      type: LOAD_TAX_SYSTEM.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TAX_SYSTEM.failure });
  }
};

export const loadEquipmentTypesList = (
  setState = () => {}
) => async dispatch => {
  try {
    dispatch({ type: LOAD_EQUIPMENT_TYPES.start });

    const data = await getEquipmentTypes();
    setState(data);
    return dispatch({
      type: LOAD_EQUIPMENT_TYPES.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_EQUIPMENT_TYPES.failure });
  }
};

export const loadDenominationsList = (
  setState = () => {}
) => async dispatch => {
  try {
    dispatch({ type: LOAD_DENOMINATIONS.start });

    const data = await getDenominations();
    setState(data);
    return dispatch({
      type: LOAD_DENOMINATIONS.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DENOMINATIONS.failure });
  }
};

export const loadBillingConceptsList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_BILLING_CONCEPT.start });

    const data = await getBillingConceptList();

    return dispatch({
      type: LOAD_BILLING_CONCEPT.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_BILLING_CONCEPT.failure });
  }
};

export const loadModels = (brandId, distributorId) => async dispatch => {
  try {
    dispatch({ type: LOAD_MODELS.start });

    let data = {};

    if (distributorId) {
      data = await getDistributorModels({ id: brandId, distributorId });
    } else {
      data = await getModels(brandId);
    }

    return dispatch({
      type: LOAD_MODELS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_MODELS.failure });
  }
};

export const loadKinships = () => async dispatch => {
  try {
    dispatch({ type: LOAD_KINSHIPS.start });

    const data = await getKinships();

    return dispatch({
      type: LOAD_KINSHIPS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_KINSHIPS.failure });
  }
};

export const loadLineOfBusiness = () => async dispatch => {
  try {
    dispatch({ type: LOAD_LINE_OF_BUSINESS.start });

    const data = await getLineOfBusiness();

    return dispatch({
      type: LOAD_LINE_OF_BUSINESS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_LINE_OF_BUSINESS.failure });
  }
};

export const loadIndustries = () => async dispatch => {
  try {
    dispatch({ type: LOAD_INDUSRTIES.start });

    const data = await getIndustries();

    return dispatch({
      type: LOAD_INDUSRTIES.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INDUSRTIES.failure });
  }
};

export const loadMsiDiscount = (setState = () => {}) => async dispatch => {
  try {
    dispatch({ type: LOAD_MSI_DISCOUNT.start });
    const data = await getMsiDiscount();

    dispatch({
      type: LOAD_MSI_DISCOUNT.success,
      data
    });

    setState(data);
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );

    dispatch({ type: LOAD_MSI_DISCOUNT.failure });
  }
};

export const loadIndustriesList = setState => async dispatch => {
  try {
    dispatch({ type: LOAD_INDUSTRY_LIST.start });

    const data = await getIndustries();

    setState(data);

    return dispatch({
      type: LOAD_INDUSTRY_LIST.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_INDUSTRY_LIST.failure });
  }
};

export const loadKinshipsList = setState => async dispatch => {
  try {
    dispatch({ type: LOAD_KINSHIP_LIST.start });

    const data = await getKinships();

    setState(data);

    return dispatch({
      type: LOAD_KINSHIP_LIST.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_KINSHIP_LIST.failure });
  }
};

export const loadBanks = () => async dispatch => {
  try {
    dispatch({ type: LOAD_CATALOG.start });

    const data = await getBanks();

    return dispatch({
      type: LOAD_CATALOG.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CATALOG.failure });
  }
};

export const loadBankList = setState => async dispatch => {
  try {
    dispatch({ type: LOAD_CATALOG.start });

    const data = await getBanks();

    setState(data);

    return dispatch({
      type: LOAD_CATALOG.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_CATALOG.failure });
  }
};

export const setModelName = name => async dispatch => {
  try {
    return dispatch({
      type: SET_MODEL_NAME,
      name
    });
  } catch (error) {
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const setDistributorId = id => async dispatch => {
  try {
    return dispatch({
      type: SET_DISTRIBUTOR_ID,
      payload: id
    });
  } catch (error) {
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const loadBrands = distributorId => async dispatch => {
  try {
    dispatch({ type: LOAD_BRANDS.start });

    let data = [];

    if (distributorId) {
      data = await getDistributorBrands(distributorId);
    } else {
      data = await getBrands();
    }

    return dispatch({
      type: LOAD_BRANDS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_BRANDS.failure });
  }
};

export const loadAdministrativeRolesList = setState => async dispatch => {
  try {
    dispatch({ type: LOAD_ADMINISTRATIVE_ROLES.start });

    const data = await getAdministrativeRoles();

    setState(data);

    return dispatch({
      type: LOAD_ADMINISTRATIVE_ROLES.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_ADMINISTRATIVE_ROLES.failure });
  }
};

export const catalogAction = (
  service,
  fetchAction,
  message
) => values => async dispatch => {
  try {
    await service(values);
    dispatch(
      showMessage({
        message,
        variant: 'success'
      })
    );
    return dispatch(fetchAction());
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createIncidentAction = catalogAction(
  createIncident,
  loadIncidents,
  'CREATE_INCIDENT'
);

export const editIncidentAction = catalogAction(
  editIncident,
  loadIncidents,
  'EDIT_INCIDENT'
);

export const deleteIncidentAction = catalogAction(
  deleteIncident,
  loadIncidents,
  'DELETE_INCIDENT'
);

export const createServiceAction = catalogAction(
  createService,
  loadServices,
  'CREATE_SERVICE'
);

export const editServiceAction = catalogAction(
  editService,
  loadServices,
  'EDIT_SERVICE'
);

export const deleteServiceAction = catalogAction(
  deleteService,
  loadServices,
  'DELETE_SERVICE'
);

export const createEquipmentTypeAction = catalogAction(
  createEquipmentType,
  loadEquipmentTypes,
  'EQUIPENT_TYPE_CREATED'
);

export const editEquipmentTypeAction = catalogAction(
  editEquipmentType,
  loadEquipmentTypes,
  'EQUIPENT_TYPE_UPDATED'
);

export const deleteEquipmentTypeAction = catalogAction(
  deleteEquipmentType,
  loadEquipmentTypes,
  'EQUIPENT_TYPE_DELETED'
);

export const createDenominationAction = catalogAction(
  createDenomination,
  loadDenominations,
  'DENOMINATION_CREATED'
);

export const editDenominationAction = catalogAction(
  editDenomination,
  loadDenominations,
  'DENOMINATION_UPDATED'
);

export const deleteDenominationAction = catalogAction(
  deleteDenomination,
  loadDenominations,
  'DENOMINATION_DELETED'
);

export const createCompanyAction = catalogAction(
  createCompany,
  loadCompanies,
  'CREATE_COMPANY'
);

export const editCompanyAction = catalogAction(
  editCompany,
  loadCompanies,
  'EDIT_COMPANY'
);

export const deleteCompanyAction = catalogAction(
  deleteCompany,
  loadCompanies,
  'DELETE_COMPANY'
);

export const createAgentAction = catalogAction(
  createAgent,
  loadAgents,
  'CREATE_AGENT'
);

export const createInsurerAction = catalogAction(
  createInsurer,
  loadInsurers,
  'CREATE_INSURER'
);

export const createIndustryAction = catalogAction(
  createIndustry,
  loadIndustries,
  'INDUSTRY_CREATED'
);

export const editIndustryAction = catalogAction(
  editIndustry,
  loadIndustries,
  'INDUSTRY_UPDATED'
);

export const deleteIndustryAction = catalogAction(
  deleteIndustry,
  loadIndustries,
  'INDUSTRY_DELETED'
);

export const createMsiDiscountAction = catalogAction(
  createMsiDiscount,
  loadMsiDiscount,
  'MSI_RATE_CREATED'
);

export const editMsiDiscountAction = catalogAction(
  editMsiDiscount,
  loadMsiDiscount,
  'MSI_DISCOUNT_UPDATED'
);

export const deleteMsiDiscountAction = catalogAction(
  deleteMsiDiscount,
  loadMsiDiscount,
  'MSI_DISCOUNT_DELETED'
);

export const createAdministrativeRoleAction = catalogAction(
  createAdministrativeRole,
  loadAdministrativesRoles,
  'ADMINISTRATIVE_ROLE_CREATED'
);

export const editAdministrativeRoleAction = catalogAction(
  editAdministrativeRole,
  loadAdministrativesRoles,
  'ADMINISTRATIVE_ROLE_UPDATED'
);

export const deleteAdministrativeRoleAction = catalogAction(
  deleteAdministrativeRole,
  loadAdministrativesRoles,
  'ADMINISTRATIVE_ROLE_DELETED'
);

export const createEconomicActivityAction = catalogAction(
  createEconomicActivity,
  loadEconomicActivities,
  'ECONOMIC_ACTIVITY_CREATED'
);

export const editEconomicActivityAction = catalogAction(
  editEconomicActivity,
  loadEconomicActivities,
  'ECONOMIC_ACTIVITY_UPDATED'
);

export const deleteEconomicActivityAction = catalogAction(
  deleteEconomicActivity,
  loadEconomicActivities,
  'ECONOMIC_ACTIVITY_DELETED'
);

export const createBrandAction = values => async dispatch => {
  dispatch({ type: CREATE_BRAND.start });

  await createBrand(values);

  dispatch(
    showMessage({
      message: 'CREATE_BRAND',
      variant: SnackBarVariants.SUCCESS
    })
  );

  dispatch({ type: CREATE_BRAND.success });

  dispatch(loadBrands(values.distributorId));
};

export const deleteBrandAction = values => async dispatch => {
  try {
    dispatch({ type: DELETE_BRAND.start });
    await deleteBrand(values);
    dispatch(
      showMessage({
        message: 'DELETE_BRAND',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: DELETE_BRAND.success
    });

    dispatch(loadBrands(values.distributorId));
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const createModelAction = (
  brandId,
  values,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: CREATE_MODEL.start });

    await createModel({
      ...values,
      brandId: Number(brandId),
      distributorId: Number(values.distributorId)
    });
    dispatch(
      showMessage({
        message: 'CREATE_MODEL',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: CREATE_MODEL.success
    });

    dispatch(loadModels(brandId, values.distributorId));
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const editBrandAction = (values, onCloseDialog) => async dispatch => {
  try {
    dispatch({ type: EDIT_BRAND.start });
    await editBrand(values);

    dispatch(
      showMessage({
        message: 'EDIT_BRAND',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({
      type: EDIT_BRAND.success
    });
    dispatch(loadBrands());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const editModelAction = (
  brandId,
  distributorId,
  values,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: EDIT_MODEL.start });

    const data = await editModel({ values });

    dispatch(
      showMessage({
        message: 'EDIT_MODEL',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({
      type: EDIT_MODEL.success,
      data
    });
    dispatch(loadModels(brandId, distributorId));
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const deleteModelAction = (
  brandId,
  id,
  distributorId,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: DELETE_MODEL.start });

    const data = await deleteModel({ id, distributorId });

    dispatch(
      showMessage({
        message: 'DELETE_MODEL',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: DELETE_MODEL.success,
      data
    });
    dispatch(loadModels(brandId, distributorId));
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const getModelsListAction = (id, setState) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_MODELS.start });

    const { system, distributorId } = getState().auth;
    const { id: distributorIdByView } = getState().distributor.detail;

    let data = {};

    if (
      system === UserType.DISTRIBUTOR ||
      distributorId ||
      distributorIdByView
    ) {
      data = await getDistributorModels({
        id,
        distributorId: distributorId || distributorIdByView
      });
    } else {
      data = await getModels(id);
    }

    setState(data);
    return dispatch({
      type: LOAD_MODELS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_MODELS.failure });
  }
};

export const createKinshipAction = (
  values,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: CREATE_KINSHIP.start });

    const data = await createKinhip(values);

    dispatch(
      showMessage({
        message: 'CREATE_KINSHIP',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: CREATE_KINSHIP.success,
      data
    });
    dispatch(loadKinships());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const editKinshipAction = (values, onCloseDialog) => async dispatch => {
  try {
    dispatch({ type: EDIT_KINSHIP.start });

    const data = await editKinship(values);

    dispatch(
      showMessage({
        message: 'UPDATE_KINSHIP',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({
      type: EDIT_KINSHIP.success,
      data
    });
    dispatch(loadKinships());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const deleteKinshipAction = (id, onCloseDialog) => async dispatch => {
  try {
    dispatch({ type: DELETE_KINSHIP.start });

    const data = await deleteKinship(id);

    dispatch(
      showMessage({
        message: 'DELETE_KINSHIP',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: DELETE_KINSHIP.success,
      data
    });
    dispatch(loadKinships());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const createLineOfBusinessAction = (
  values,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: CREATE_LINE_OF_BUSINESS.start });

    const data = await createLineOfBusiness(values);

    dispatch(
      showMessage({
        message: 'CREATE_LINE_OF_BUSINESS',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: CREATE_LINE_OF_BUSINESS.success,
      data
    });
    dispatch(loadLineOfBusiness());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const editLineOfBusinessAction = (
  values,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: EDIT_LINE_OF_BUSINESS.start });

    const data = await editLineOfBusiness(values);

    dispatch(
      showMessage({
        message: 'UPDATE_LINE_OF_BUSINESS',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({
      type: EDIT_LINE_OF_BUSINESS.success,
      data
    });
    dispatch(loadLineOfBusiness());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const deleteLineOfBusinessAction = (
  id,
  onCloseDialog
) => async dispatch => {
  try {
    dispatch({ type: DELETE_LINE_OF_BUSINESS.start });

    const data = await deleteLineOfBusiness(id);

    dispatch(
      showMessage({
        message: 'DELETE_LINE_OF_BUSINESS',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({
      type: DELETE_LINE_OF_BUSINESS.success,
      data
    });
    dispatch(loadLineOfBusiness());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const createBankAction = (values, onCloseDialog) => async dispatch => {
  try {
    dispatch({ type: CREATE_BANK.start });

    await createBank(values);

    dispatch(
      showMessage({
        message: 'CREATE_BANK',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: CREATE_BANK.success });

    dispatch(loadBanks());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const editBankAction = (values, onCloseDialog) => async dispatch => {
  try {
    dispatch({ type: EDIT_BANK.start });

    await editBank(values);

    dispatch(
      showMessage({
        message: 'UPDATE_BANK',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: EDIT_BANK.success });

    dispatch(loadBanks());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const deleteBankAction = (values, onCloseDialog) => async dispatch => {
  try {
    dispatch({ type: DELETE_BANK.start });

    await deleteBank(values);

    dispatch(
      showMessage({
        message: 'DELETE_BANK',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch({ type: DELETE_BANK.success });

    dispatch(loadBanks());
    return onCloseDialog();
  } catch (error) {
    onCloseDialog();
    return dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
  }
};

export const calculateDiscountGridAction = values => async dispatch => {
  try {
    dispatch({ type: CALCULATE_DISCOUNT_GRID.start });

    const data = await calculateDiscountGrid(values);

    return dispatch({ type: CALCULATE_DISCOUNT_GRID.success, payload: data });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CALCULATE_DISCOUNT_GRID.failure });
  }
};
