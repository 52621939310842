import { resendSignEmail } from 'src/services/signingService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import { showMessage } from './snackBarActions';

const { getUploadLink, uploadFile } = require('src/services/serviceBase');

export const RESEND_SIGN_EMAIL = asyncActionCreator('RESEND_SIGN_EMAIL');

const uploadSingleFile = async file => {
  if (!file) return Promise.reject(new Error('No file'));
  const { type } = file;
  const extension = file.name.split('.').pop();
  if (!extension) {
    throw new Error('No extension :(');
  }

  const link = await getUploadLink({ format: extension, contentType: type });
  const url = new URL(link);
  const attachmentId = url.pathname.slice(1);
  await uploadFile({ targetURL: link, file });
  return `${process.env.REACT_APP_BUCKET_URL}${attachmentId}`;
};

export const getCommonOptions = (
  getState,
  optionsSelectorCallback,
  sendLimit = true
) => {
  const queryOpts = optionsSelectorCallback(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
};

export const resendSignEmailAction = weetrustDocId => async dispatch => {
  try {
    dispatch({ type: RESEND_SIGN_EMAIL.start });
    await resendSignEmail(weetrustDocId);
    dispatch(
      showMessage({
        message: 'SEND_TO_SIGN_SUCCESSFULLY',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({ type: RESEND_SIGN_EMAIL.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    dispatch({ type: RESEND_SIGN_EMAIL.failure });
  }
};

export default uploadSingleFile;
