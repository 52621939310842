import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import AddButton from 'src/components/AddButton/AddButton';
import { createMsiDiscountAction } from 'src/store/actions/catalogActions';
import useStyles from './styles';
import MsiDiscountForm from './MsiDiscountForm';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreate = useCallback(
    values => {
      dispatch(createMsiDiscountAction(values, handleCloseModal()));
    },
    [dispatch, handleCloseModal]
  );

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: '100%'
          }}
        >
          <Box className={classes.actionsContainer}>
            <AddButton
              style={{ margin: '10px' }}
              title={t('CATALOGS_VIEW.MSI_DISCOUNT.ADD_MSI_DISCOUNT')}
              onClick={handleOpenModal}
            />
            <MsiDiscountForm
              open={open}
              onClose={handleCloseModal}
              onCreate={handleCreate}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  contractStatus: PropTypes.string
};

export default Toolbar;
