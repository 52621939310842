import React from 'react';
import PropTypes from 'prop-types';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { Link } from 'react-router-dom';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';

const FailuresCategoriesRow = ({
  contractFolio,
  client,
  clientId,
  contractId,
  log,
  amount,
  dueDate
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">
        <Tooltip title={contractFolio}>
          <Link to={`/contracts/${contractId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {contractFolio}
            </Typography>
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {client && client.length > 45 ? (
          <Tooltip title={client}>
            <Link to={`/clients/detail/${clientId}`}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '1.43'
                }}
              >
                {`${client.slice(0, 45)}...`}
              </Typography>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/clients/detail/${clientId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {client}
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(amount)}</TableCell>
      <TableCell align="center">{log || 'No data'}</TableCell>
      <TableCell align="center">
        {selectFormatDate(dueDate, dateFormatType.DAY)}
      </TableCell>
    </TableRow>
  );
};

FailuresCategoriesRow.propTypes = {
  contractFolio: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  log: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired
};

export default FailuresCategoriesRow;
