import * as Yup from 'yup';

export default (parameter) => Yup.object().shape({
  distributorEquipment: Yup.object().required(
    'SCHEMA_ERRORS.DISTRIBUTOR_EQUIPMENT_REQUIRED'
  ),
  equipmentInsuranceIncluded: Yup.boolean().required(
    'SCHEMA_ERRORS.REQUIRED_FIELD'
  ),
  price: Yup.number().required('SCHEMA_ERRORS.PRICE_REQUIRED'),
  gracePeriod: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  initialPayment: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  paymentInputOption: Yup.string().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  residualPayment: Yup.number().required()
	.test(
		'residual-payment-validation',
		'El valor del pago residual excede el límite',
		// eslint-disable-next-line func-names, consistent-return
		function (value) {
			if (value === 0) {
				return this.createError({ message: 'El pago residual debe ser de al menos $1' });
			}
			if (parameter && value) {
				const { price } = this.parent;
				if (Number(parameter.maxResidualValue) === 0 && value === 1) {
					return true;
				} 
				const maxValue = (price * Number(parameter.maxResidualValue)) / 100;
				return value > 0 && value <= maxValue;
			}
		}
	),
});
