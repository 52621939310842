const DiscountGridInitalState = [
  {
    initialPayment: 5,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  },
  {
    initialPayment: 10,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  },
  {
    initialPayment: 15,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  },
  {
    initialPayment: 20,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  },
  {
    initialPayment: 25,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  },
  {
    initialPayment: 30,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  },
  {
    initialPayment: 35,
    discountTwelve: 0,
    discountEighteen: 0,
    discountTwentyFour: 0
  }
];

export default DiscountGridInitalState;
