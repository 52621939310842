import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  selectDistributorCatalogs,
  selectDistributorList
} from 'src/store/selectors/distributorSelectors';
import {
  LOAD_DISTRIBUTORS,
  LOAD_DISTRIBUTOR_CATALOGS,
  loadDistributorEquipmentsCatalog
} from 'src/store/actions/distributorActions';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import EquipmentAlert from './EquipmentAlert';

const calculatePriceWhitIva = (price, iva) => {
  return Number(price + (price * iva) / 100).toFixed(2);
};

const StepOne = ({ data, handleBack, handleNext, resetRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const loadingDistributors = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTORS.action])
  );
  const loadingDistributorEquipments = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );
  const { t } = useTranslation();
  const distributorList = useSelector(selectDistributorList);
  const equipments = useSelector(selectDistributorCatalogs);

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  const onBack = useCallback(
    resetForm => () => {
      resetForm();
      handleBack();
    },
    [handleBack]
  );

  const onLoadDistributorEquipments = useCallback(id => {
    dispatch(loadDistributorEquipmentsCatalog(id, {}, false));
  }, []);

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                mt: 3
              }}
            >
              <EquipmentAlert
                equipments={equipments}
                distributors={distributorList}
                selectedDistributor={values.distributor}
                loading={loadingDistributors || loadingDistributorEquipments}
              />
            </Box>
            <Autocomplete
              options={distributorList}
              id="distributor"
              getOptionLabel={option => (option ? `${option.name}` : '')}
              loading={loadingDistributors}
              fullWidth
              onChange={(_e, value) => {
                setFieldValue('distributor', value ?? '');
                onLoadDistributorEquipments(value.id);
                setFieldValue('distributorEquipment', '');
              }}
              sx={{ marginBottom: 3, marginTop: 2 }}
              value={values.distributor ?? ''}
              clearIcon={null}
              label={t('COMMON.DISTRIBUTOR')}
              FormHelperTextProps={{ sx: { color: 'red' } }}
              helperText={touched.distributor && t(errors.distributor)}
              error={Boolean(touched.distributor && errors.distributor)}
            />

            <Tooltip
              title={
                !values.distributor
                  ? `${t('TOOLTIP_MESSAGES.SELECT_A_DISTRIBUTOR')}`
                  : null
              }
            >
              <Autocomplete
                options={equipments}
                id="equipment"
                sx={{ marginBottom: 1, marginTop: 2 }}
                getOptionLabel={option =>
                  option ? `${option.brand?.name} - ${option.model}` : ''
                }
                loading={loadingDistributorEquipments}
                fullWidth
                onChange={(_e, value) => {
                  setFieldValue('distributorEquipment', value ?? '');
                  setFieldValue('price', value.price);
                }}
                value={values.distributorEquipment ?? ''}
                clearIcon={null}
                disabled={!equipments.length}
                label={t('COMMON.EQUIPMENT')}
                helperText={
                  touched.distributorEquipment && t(errors.distributorEquipment)
                }
                error={Boolean(
                  touched.distributorEquipment && errors.distributorEquipment
                )}
              />
            </Tooltip>

            <Tooltip
              title={
                !values.distributor?.isPriceEditable
                  ? `${t('TOOLTIP_MESSAGES.NOT_EDITABLE_EQUIPMENT_PRICE')}`
                  : null
              }
            >
              <TextField
                InputProps={{
                  autoComplete: 'off',
                  inputComponent: MoneyMask
                }}
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && t(errors.price)}
                label={t('COMMON.PRICE')}
                margin="normal"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                variant="outlined"
                disabled={!values.distributor?.isPriceEditable}
              />
            </Tooltip>

            <TextField
              fullWidth
              label={`${t('COMMON.IVA')} %`}
              margin="normal"
              name="iva"
              value={formatAmountWithDecimals(values.iva)}
              variant="outlined"
              disabled
              inputProps={{ step: 0.1 }}
            />

            <TextField
              disabled
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              fullWidth
              label={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
              margin="normal"
              name="priceWhitIva"
              onBlur={handleBlur}
              onChange={handleChange}
              value={calculatePriceWhitIva(values.price, values.iva)}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.moreInfo && errors.moreInfo)}
              fullWidth
              helperText={touched.moreInfo && t(errors.moreInfo)}
              label={t('COMMON.MORE_INFO')}
              name="moreInfo"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              margin="normal"
              type="text"
              maxRows={4}
              value={values.moreInfo}
              variant="outlined"
              inputProps={{ maxLength: 120, autoComplete: 'off' }}
            />

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={onBack(resetForm)}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepOne;
