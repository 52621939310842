import * as Yup from 'yup';

export default Yup.object().shape({
  minTerm: Yup.number()
    .when('maxTerm', (maxTerm, schema) => {
      return schema.test('minTerm', 'SCHEMA_ERRORS.MIN_TERM', value => {
        return value <= maxTerm;
      });
    })
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  maxTerm: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  isPriceEditable: Yup.boolean().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  minInitialPayment: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  maxResidualValue: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  isInsuranceIncluded: Yup.boolean().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  maxGracePeriod: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  enableEditKamInfo: Yup.boolean().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  enableUseKamContactInfo: Yup.boolean().required(
    'SCHEMA_ERRORS.VALUE_REQUIRED'
  ),

  // defaultGracePeriod cannot be bigger than maxGracePeriod
  defaultGracePeriod: Yup.number()
    .when('maxGracePeriod', (maxGracePeriod, schema) => {
      return schema.test(
        'defaultGracePeriod',
        'SCHEMA_ERRORS.DEFAULT_GRACE_PERIOD',
        value => {
          return value <= maxGracePeriod;
        }
      );
    })
    .required('SCHEMA_ERRORS.VALUE_REQUIRED')
});
