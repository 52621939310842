import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import { Link } from 'react-router-dom';

const GeneralContractDetail = ({ contract }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('COMMON.CLIENT')}
        value={
          <Link to={`/clients/detail/${contract.clientId}`}>
            {contract.clientName}
          </Link>
        }
      />
      <DetailRow
        name={t('COMMON.EQUIPMENT')}
        value={
          <Link to={`/inventory/${contract.equipmentId}`}>
            {contract.equipmentId}
          </Link>
        }
      />
      <DetailRow
        name={t('CONTRACT_VIEW.RATE')}
        value={`${contract.rate.toFixed(3)} %`}
      />
      <DetailRow
        style={{
          display: 'none'
        }}
        name={t('CONTRACT_VIEW.CAT')}
        value={
          contract.cat ? `${contract.cat.toFixed(1)} %` : t('COMMON.NO_DATA')
        }
      />
      <DetailRow name={t('CONTRACT_VIEW.TERM')} value={`${contract.term}`} />
      <DetailRow
        name={t('CONTRACT_VIEW.EQUIPMENT_COST')}
        value={formatPositiveAmount(contract.equipmentCost)}
      />
      <DetailRow
        name={t('CONTRACT_VIEW.INITIAL_PAYMENT')}
        value={formatPositiveAmount(contract.initialPayment)}
      />
      <DetailRow
        name={t('CONTRACT_VIEW.NET_INVESTMENT')}
        value={formatPositiveAmount(contract.netInvestment)}
      />
      <DetailRow
        name={t('CONTRACT_VIEW.RESIDUAL')}
        value={formatPositiveAmount(contract.residualPayment)}
      />
    </>
  );
};

GeneralContractDetail.propTypes = {
  contract: PropTypes.object.isRequired
};
export default GeneralContractDetail;
