import { PaymentInputOption } from './constants/quotes';

// eslint-disable-next-line import/prefer-default-export
export const validateTermAndInitialPayment = (
  values,
  setFieldError,
  data,
  t,
  isMsiModality = false
) => {
  let validated = true;

  if (values.paymentInputOption === PaymentInputOption.AMOUNT) {
    if (
      values.initialPayment >
      (Number(values.price) * Number(data.maxInitialPaymentPercentage)) / 100
    ) {
      setFieldError(
        'initialPayment',
        t('QUOTES_VIEW.QUICK_QUOTE_FORM.INITIAL_PAYMENT_EXCEEDED').replace(
          '{amount}',
          data.maxInitialPaymentPercentage
        )
      );
      validated = false;
    }
    if (
      values.initialPayment <
      (Number(values.price) * Number(data.minInitialPaymentPercentage)) / 100
    ) {
      setFieldError(
        'initialPayment',
        t('QUOTES_VIEW.QUICK_QUOTE_FORM.INITIAL_PAYMENT_LOWER').replace(
          '{amount}',
          data.minInitialPaymentPercentage
        )
      );
      validated = false;
    }
  } else if (values.paymentInputOption === PaymentInputOption.PERCENTAGE) {
    if (values.initialPayment < Number(data.minInitialPaymentPercentage)) {
      setFieldError(
        'initialPayment',
        t('QUOTES_VIEW.QUICK_QUOTE_FORM.INITIAL_PAYMENT_LOWER').replace(
          '{amount}',
          data.minInitialPaymentPercentage
        )
      );
      validated = false;
    }
    if (values.initialPayment > Number(data.maxInitialPaymentPercentage)) {
      setFieldError(
        'initialPayment',
        t('QUOTES_VIEW.QUICK_QUOTE_FORM.INITIAL_PAYMENT_EXCEEDED').replace(
          '{amount}',
          data.maxInitialPaymentPercentage
        )
      );
      validated = false;
    }
  }
  if (!isMsiModality) {
    if (values.term < data.minTerm) {
      setFieldError(
        'term',
        t('QUOTES_VIEW.QUICK_QUOTE_FORM.MIN_TERM').replace(
          '{term}',
          data.minTerm
        )
      );
      validated = false;
    }
    if (values.term > data.maxTerm) {
      setFieldError(
        'term',
        t('QUOTES_VIEW.QUICK_QUOTE_FORM.MAX_TERM').replace(
          '{term}',
          data.maxTerm
        )
      );
      validated = false;
    }
  }

  return validated;
};
